<template>
    <div class="button" @click="$emit('click')">
        <i :class="icon"></i>
        {{text}}
    </div>
</template>
<script>
export default {
    props: ["icon", "text"]
}
</script>
<style lang="less" scoped>
.button {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
    text-align: center;
    background-color: rgba(0, 185, 231, 1);
    color: #fff;
    cursor: pointer;
    &:hover {
        background-color: rgba(3, 165, 205, 1);
    }
}
</style>