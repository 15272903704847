<template>
  <div class="page detail">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-hotdog" text="渠道详情" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">渠道名称：</div>
            <div class="content">
              {{Form.name}}
            </div>
          </div>
          <div class="search-item">
            
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">所属灌域：</div>
            <div class="content" v-if="Form.irrigationDistrictList.length > 0">
              <span v-for="item in Form.irrigationDistrictList" :key="item.id">
                {{item.name}} 
              </span>
            </div>
          </div>
          <div class="search-item">
            <div class="label">渠道级别：</div>
            <div class="content">
              {{Form.level.name}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">设计流量：</div>
            <div class="content">
              {{Form.designFlow}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">运行状况：</div>
            <div class="content">
              {{Form.runningState.name}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">渠系水利用系数：</div>
            <div class="content">
              {{Form.coefficient}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">渠道长度：</div>
            <div class="content">
              {{Form.length}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">灌溉面积：</div>
            <div class="content">
              {{Form.irrigationArea}}
            </div>
          </div>
          <div class="search-item">
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/channel/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from 'vuex';
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "渠道管理",
          path: "/basic/channel/page",
        },
        {
          text: "渠道详情",
          path: null,
        },
      ],
      Form: {
        code: null,
        name: null,
        irrigationDistrict: {
          name: null
        },
        level: {
          name: null
        },
        designFlow: null,
        coefficient: null,
        length: null,
        irrigationArea: null,
        runState: {
          name: null
        }
      },
    };
  },
  methods: {
    ...mapActions(["ChannelDetail"]),
    queryData(id) {
      this.ChannelDetail({
        _this: this,
        id,
        data: this.Form
      }).then(res => {
        if (res !== false) {
          this.Form = {
            ...this.Form,
            ...res.body
          };
        }
      }).catch()
    }
  },
  mounted() {
      this.$nextTick(() => {
        let arr = this.$route.path.split("/");
        this.queryData(arr[arr.length - 1]);
      })
  }
};
</script>