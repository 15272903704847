<template>
  <div class="page detail">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-list-ul" text="引用水计划详情" />
    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        基础信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">年份：</div>
            <div class="content">
              {{Form.year}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">灌域：</div>
            <div class="content">
              {{Form.irrigationDistrict.name}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">协会：</div>
            <div class="content">
              {{Form.organizationName}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">当年引水指标：</div>
            <div class="content">
              {{Form.diversionIndex}}万m³
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">
              当年计划引水量：
            </div>
            <div class="content">
              {{Form.diversionPlanQuantity}}万m³
            </div>
          </div>
          <div class="search-item">
            <div class="label">
              当年实际引水量：
            </div>
            <div class="content">
              {{Form.diversionActualQuantity}}万m³
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">当年用水指标：</div>
            <div class="content">
              {{Form.usageIndex}}万m³
            </div>
          </div>
          <div class="search-item">
            <div class="label">
              当年计划用水量：
            </div>
            <div class="content">
              {{Form.usagePlanQuantity}}万m³
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">
              当年实际用水量：
            </div>
            <div class="content">
              {{Form.usageActualQuantity}}万m³
            </div>
          </div>
          <div class="search-item">

          </div>
        </div>
      </div>
    </div>
    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        月计划用水量
      </div>
      <div class="apply-main">
        <div class="row" v-for="item in 6" :key="item">
          <div class="search-item">
            <div class="label">
              {{ (item - 1) * 2 + 1 }}月计划用水量：
            </div>
            <div class="content" v-if="Form.waterPlanMonths.length > 0">
              {{Form.waterPlanMonths[(item - 1) * 2].quantity}}万m³
            </div>
          </div>
          <div class="search-item">
            <div class="label">
              {{ (item) * 2 }}月计划用水量：
            </div>
            <div class="content" v-if="Form.waterPlanMonths.length > 0">
              {{Form.waterPlanMonths[(item - 1) * 2 + 1].quantity}}万m³
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/water/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from 'vuex';
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "引用水计划",
          path: "/basic/water/page",
        },
        {
          text: "引用水计划详情",
          path: null,
        },
      ],
      Form: {
        year: null,
        irrigationDistrict: {
          name: null
        },
        diversionIndex: null,
        diversionPlanQuantity: null,
        diversionActualQuantity: null,
        usageIndex: null,
        total: null,
        waterPlanMonths: []
      },
      options: [
        {
          value: 1,
          label: "1号闸门",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["WaterDetail"]),
    queryData(id) {
      this.WaterDetail({
        _this: this,
        id,
        data: this.Form
      }).then(res => {
        if (res !== false) {
          this.Form = {
            ...this.Form,
            ...res.body
          };
        }
      }).catch()
    }
  },
  mounted() {
      this.$nextTick(() => {
        let arr = this.$route.path.split("/");
        this.queryData(arr[arr.length - 1]);
      })
  }
};
</script>
<style lang="less" scoped>
</style>