<template>
  <div class="login">
    <div class="login-title">
      <img src="@/assets/logo2.png" class="login-logo" alt="" />
      闸门测控一体化管理平台
    </div>
    <div class="login-form">
      <div class="form-title">系统登录</div>
      <div class="itemBar">
        <el-input
          v-model="Form.account"
          @keydown.enter.native.prevent="login"
          placeholder="请输入用户名"
          minlength="4"
          maxlength="20"
          clearable
        >
        </el-input>
        <i class="fa fa-user bar-icon"></i>
      </div>
      <div class="itemBar">
        <el-input
          placeholder="请输入密码"
          v-model="Form.password"
          maxlength="20"
          @keydown.enter.native.prevent="login"
          show-password
          clearable
        >
        </el-input>
        <i class="fa fa-lock bar-icon"></i>
      </div>
      <div class="itemBar">
        <el-input
          v-model="Form.code"
          @keydown.enter.native.prevent="login"
          type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
          placeholder="请输入验证码"
          maxlength="4"
          minlength="4"
          clearable
        >
        </el-input>
        <i class="fa fa-code bar-icon"></i>
        <img
          :src="imgSrc"
          width="80"
          height="40"
          class="code-img"
          @click="refreshCode"
        />
      </div>

      <div class="login-btn itemBar" @click="login()">登录</div>
    </div>
  </div>
</template>
<script>
import CryptoJS from "crypto-js";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      Form: {
        account: "",
        password: "",
        code: "",
        uid: "",
      },
      imgSrc: "",
    };
  },
  watch: {
    "Form.uid"(val) {
      this.imgSrc = window.config.api + "/v1/auth/captcha?uid=" + val;
    },
  },
  methods: {
    ...mapActions(["Login", "todoList", "getMyTree", "getMineInfo"]),
    // 生成32位uid
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    // 刷新验证码
    refreshCode() {
      this.Form.uid = this.randomString(32);
    },
    // 加密
    encrypt(word) {
      var key = CryptoJS.enc.Utf8.parse(this.Form.uid);
      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.toString();
    },
    // 登录
    login() {
      if (this.Form.account === "" || this.Form.account === null) {
        this.$message.warning("用户名不能为空！");
        return;
      }
      if (this.Form.password === "" || this.Form.password === null) {
        this.$message.warning("密码不能为空！");
        return;
      }
      if (this.Form.code === "" || this.Form.code === null) {
        this.$message.warning("验证码不能为空！");
        return;
      }
      this.Login({
        _this: this,
        data: {
          ...this.Form,
          password: this.encrypt(this.Form.password),
        },
      })
        .then((res) => {
          if (res !== false) {
            // 登录成功，保存token
            let token = res.body.type + " " + res.body.credentials;
            window.sessionStorage.setItem("user_token", token);
            this.$message.success("登录成功！");
            this.$router.push("/home");
            // 初始化数据
            this.todoList({
              _this: this,
            })
              .then((res) => {
                if (res !== false) {
                  this.$store.commit("saveTodoCount", res.body.recordCount);
                }
              })
              .catch();
            this.getMyTree(this)
              .then((res) => {
                if (res !== false) {
                  this.$store.commit("saveMyTree", res.body);
                }
              })
              .catch();
            this.getMineInfo(this)
              .then((res) => {
                if (res !== false) {
                  this.$store.commit("saveMyInfo", res.body);
                }
              })
              .catch();
          } else {
            this.refreshCode();
          }
        })
        .catch();
    },
  },
  mounted() {
    this.refreshCode();
    var token = sessionStorage.getItem("user_token");
    if (token && token != "") {
      this.$router.push("/home");
    }
  },
};
</script>
<style lang="less" scoped>
.login {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: url("../assets/bg.jpg");
  z-index: 101;
  .login-title {
    margin-top: 120px;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    .login-logo {
      transform: translateY(10px);
      width: 40px;
    }
  }
  .login-form {
    width: 350px;
    height: 320px;
    background-color: rgb(241, 242, 246);
    border-radius: 10px;
    box-shadow: 2px 2px 2px rgb(45, 155, 253);
    position: absolute;
    left: calc(50% - 175px);
    top: 200px;
    text-align: center;
    .form-title {
      height: 50px;
      line-height: 50px;
      font-size: 22px;
      margin-top: 20px;
      font-weight: bold;
    }
    .itemBar {
      padding: 0 20px;
      margin-top: 20px;
      position: relative;
      .bar-icon {
        position: absolute;
        left: 40px;
        top: 12px;
      }
      .code-img {
        position: absolute;
        top: 3px;
        right: 22px;
        height: 34px;
      }
    }

    .login-btn {
      height: 40px;
      line-height: 40px;
      text-align: center;
      width: calc(100% - 40px);
      margin-left: 20px;
      cursor: pointer;
      background-color: rgb(45, 155, 253);
      color: #fff;
    }
  }
}
</style>