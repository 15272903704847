<template>
  <el-autocomplete
    v-model="val"
    :fetch-suggestions="querySearchAsync"
    @select="handleSelect"
    @change="handleChange"
  ></el-autocomplete>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["value", "gateId"],
  data() {
    return {
      val: "",
    };
  },
  methods: {
    ...mapActions(["StationPage"]),
    handleSelect(val) {
      this.$emit("select", val.id);
    },
    handleChange(val) {
      val = val == "" ? null : 999
      this.$emit("change", val);
    },
    querySearchAsync(queryString, cb) {
      let restaurants = [];
      this.StationPage({
        _this: this,
        data: {
          pageIndex: 1,
          pageSize: 999,
          type: 1,
          nameLike: queryString,
        },
      })
        .then((res) => {
          if (res !== false) {
            res.body.records.forEach((item) => {
              restaurants.push({
                id: item.stationId,
                value: item.stationName,
              });
            });
            cb(restaurants);
          }
        })
        .catch();
    },
  },
  mounted() {
    if (this.value) {
      this.val = this.value;
    }
    if (this.gateId) {
      this.StationPage({
        _this: this,
        data: {
          pageIndex: 1,
          pageSize: 999,
          type: 1,
        },
      })
        .then((res) => {
          if (res !== false) {
            res.body.records.forEach(item => {
              if (item.stationId == this.gateId) {
                this.val = item.stationName
              }
            })
          }
        })
        .catch();
    }
  },
};
</script>