<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-list-ul" text="引用水计划" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">年份：</div>
          <div class="content">
            <el-date-picker
              v-model="Query.year"
              type="year"
              placeholder="选择年"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="search-item">
          <div class="label">灌域：</div>
          <div class="content">
            <IrrigationSelect
              @change="(val) => (Query.irrigationDistrictId = val)"
              @select="handleSelect"
            />
          </div>
        </div>
        <div class="search-item">
          <div class="label">协会：</div>
          <div class="content">
            <Organization
              @change="(val) => (Query.organizationId = val)"
              :typeId="6"
              @select="handleSelect2"
            />
          </div>
        </div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-plus"
          class="btn"
          text="添加"
          @click="$router.push('/basic/water/add')"
        />
        <Button
          icon="fa fa-trash-alt"
          class="btn"
          text="删除"
          style="margin-left: 10px"
          @click="del()"
        />
        <Button
          icon="fa fa-search"
          class="btn fr clearfix"
          text="查询"
          @click="queryData()"
        />
      </div>
      <div class="table-wrapper">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="年份" width="80">
            <template slot-scope="scope">
              <span
                class="link"
                @click="$router.push('/basic/water/detail/' + scope.row.id)"
                >{{ scope.row.year }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="irrigationDistrict.name"
            label="灌域"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="organizationName"
            label="协会"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="diversionIndex"
            label="当年引水指标(万m³)"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="diversionPlanQuantity"
            label="当年计划引水量(万m³)"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="diversionActualQuantity"
            label="当年实际引水量(万m³)"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="usageIndex"
            label="当年用水指标(万m³)"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="usagePlanQuantity"
            label="当年计划用水量(万m³)"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="usageActualQuantity"
            label="当年实际用水量(万m³)"
            width="180"
          ></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <span
                class="link"
                v-if="scope"
                @click="$router.push('/basic/water/edit/' + scope.row.id)"
              >
                <i class="fa fa-edit"></i>
                编辑
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="(val) => (Query.pageSize = val)"
          @current-change="(val) => (Query.pageIndex = val)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import IrrigationSelect from "@/components/IrrigationSelect";
import Title from "@/components/Title";
import Button from "@/components/Button";
import Organization from "@/components/Organization";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    IrrigationSelect,
    Title,
    Button,
    Organization,
  },
  watch: {
    "Query.pageIndex"() {
      this.queryData();
    },
    "Query.pageSize"() {
      this.queryData();
    },
  },
  data() {
    return {
      arr: [
        {
          text: "基础信息",
          path: null,
        },
        {
          text: "引用水计划",
          path: null,
        },
      ],
      Query: {
        year: null,
        irrigationDistrictId: null,
        organizationId: null,
        pageIndex: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0,
      multipleSelection: [],
    };
  },
  methods: {
    ...mapActions(["WaterPage", "DelWater"]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelect(val) {
      this.Query.irrigationDistrictId = val;
    },
    handleSelect2(val) {
      this.Query.organizationId = val;
    },
    queryData() {
      this.WaterPage({
        _this: this,
        data: {
          ...this.Query,
          year:
            this.Query.year == null
              ? this.Query.year
              : this.$options.filters["dateformat"](this.Query.year, "YYYY"),
        },
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
    del() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning("请选中您要删除的行！");
        return;
      }
      let arr = this.multipleSelection.map((item) => item.id);
      this.DelWater({
        _this: this,
        data: arr,
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("删除成功！");
            this.queryData();
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 412px);
}
</style>