<template>
  <div class="page detail">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-broadcast-tower" text="测站详情" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">测站名称：</div>
            <div class="content">
              {{Form.station.name}}
            </div>
          </div>
          <div class="search-item"></div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">所属灌域：</div>
            <div class="content">
              {{Form.station.irrigationDistrict.name}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">管理机构：</div>
            <div class="content">
              {{Form.station.organization.name}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">行政区划：</div>
            <div class="content">
              {{Form.region.name}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">站址：</div>
            <div class="content">
              {{Form.station.address}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">测站类型：</div>
            <div class="content">
              {{Form.type.name}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">始报年月：</div>
            <div class="content">
              {{Form.firstDate | dateformat('YYYY-MM')}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">建站日期：</div>
            <div class="content">
              {{Form.buildDate | dateformat('YYYY-MM-DD')}}
            </div>
          </div>
          <div class="search-item">

          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/station/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "基础信息",
          path: null,
        },
        {
          text: "测站管理",
          path: "/basic/station/page",
        },
        {
          text: "测站详情",
          path: null,
        },
      ],
      Form: {
        station: {
          name: null,
          irrigationDistrict: {
            id: null,
            name: null
          },
          organization: {
            id: null,
            name: null
          },
        },
        type: {
          id: null,
          name: null
        },
        region: {
          name: null
        },
        address: null,
        buildDate: null,
        firstDate: null,
      },
    };
  },
  methods: {
    ...mapActions(["StationDetail"]),
    // 测站详情
    queryData() {
      this.StationDetail({
        _this: this,
        id: this.Form.id,
      })
        .then((res) => {
          if (res !== false) {
            this.Form = {
              ...this.Form,
              ...res.body,
            };
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      let arr = this.$route.path.split("/");
      this.Form.id = arr[arr.length - 1];

      this.queryData();
    });
  },
};
</script>