<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-broadcast-tower" text="添加测站" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 测站名称：</div>
            <div class="content">
              <el-input v-model="Form.station.name" clearable maxlength="32">
              </el-input>
            </div>
          </div>
          <div class="search-item"></div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 所属灌域：</div>
            <div class="content">
              <IrrigationSelect
                @change="val => Form.station.irrigationDistrict.id = val"
                @select="handleSelect"
              />
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 管理机构：</div>
            <div class="content">
              <Organization
                @change="val => Form.station.organization.id = val"
                @select="handleSelect2"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 行政区划：</div>
            <div class="content">
              <RegionTree @change="val => Form.regionCode = val" />
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 站址：</div>
            <div class="content">
              <el-input v-model="Form.station.address" clearable maxlength="32">
              </el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 测站类型：</div>
            <div class="content">
              <el-select v-model="Form.type.id">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="label">始报年月：</div>
            <div class="content">
              <el-date-picker
                v-model="Form.firstDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">建站日期：</div>
            <div class="content">
              <el-date-picker
                v-model="Form.buildDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="search-item"></div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/station/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions, mapState } from "vuex";
import IrrigationSelect from "@/components/IrrigationSelect";
import Organization from "@/components/Organization";
import RegionTree from "@/components/RegionTree";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    IrrigationSelect,
    Organization,
    RegionTree
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  data() {
    return {
      arr: [
        {
          text: "基础信息",
          path: null,
        },
        {
          text: "测站管理",
          path: "/basic/station/page",
        },
        {
          text: "添加测站",
          path: null,
        },
      ],
      Form: {
        station: {
          name: null,
          irrigationDistrict: {
            id: null,
          },
          organization: {
            id: null,
          },
          address: null,
        },
        type: {
          id: null,
        },
        regionCode: null,
        buildDate: null,
        firstDate: null,
      },
      options: [],
    };
  },
  methods: {
    ...mapActions(["AddStation", "getDictionaryForType"]),
    handleSelect(val) {
      this.Form.station.irrigationDistrict.id = val;
    },
    handleSelect2(val) {
      this.Form.station.organization.id = val;
    },
    // 提交表单
    saveForm() {
      if (this.Form.station.name === null || this.Form.station.name === "") {
        this.$message.warning("测站名称不能为空！");
        return;
      }
      if (
        this.Form.station.irrigationDistrict.id === null ||
        this.Form.station.irrigationDistrict.id === ""
      ) {
        this.$message.warning("所属灌域不能为空！");
        return;
      }
      if (
        this.Form.station.irrigationDistrict.id === 999
      ) {
        this.$message.warning("不存在您输入的灌域名称，请通过下拉点击填入灌域！");
        return;
      }
      if (
        this.Form.station.organization.id === null ||
        this.Form.station.organization.id === ""
      ) {
        this.$message.warning("管理机构不能为空！");
        return;
      }
      if (
        this.Form.station.organization.id === 999
      ) {
        this.$message.warning("不存在您输入的管理机构名称，请通过下拉点击填入管理机构！");
        return;
      }
      if (this.Form.regionCode === null || this.Form.regionCode === "") {
        this.$message.warning("行政区划不能为空！");
        return;
      }
      if (
        this.Form.station.address === null ||
        this.Form.station.address === ""
      ) {
        this.$message.warning("站址不能为空！");
        return;
      }
      if (this.Form.type.id === null || this.Form.type.id === "") {
        this.$message.warning("测站类型不能为空！");
        return;
      }
      this.AddStation({
        _this: this,
        id: this.Form.id,
        data: {
          ...this.Form,
          buildDate: this.Form.buildDate != null && this.Form.buildDate != "" ? this.$utils.fixDateTime(this.Form.buildDate) : null,
          firstDate: this.Form.firstDate != null && this.Form.firstDate != "" ? this.$utils.fixDateTime(this.Form.firstDate) : null,
        },
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("新增成功！");
            this.$router.push("/basic/station/page");
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 获取测站类型下拉选项
      this.options = this.allOptions.filter((item) => item.type === "StationType");
      if (this.options.length > 0) {
        this.options = this.options[0].arr;
      } else {
        // 没有就去查
        this.getDictionaryForType({
          _this: this,
          type: "StationType",
        })
          .then((res) => {
            if (res !== false) {
              res.body.options.forEach((item) => {
                this.options.push({
                  value: item.id + "",
                  label: item.name,
                });
              });
            }
          })
          .catch();
      }
    });
  },
};
</script>