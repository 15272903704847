<template>
  <div class="page apply">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-file-signature" text="用水申请" />
    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        申请信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 申请开始时间：</div>
            <div class="content">
              <el-date-picker
                v-model="Form.startTime"
                type="datetime"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 申请结束时间：</div>
            <div class="content">
              <el-date-picker v-model="Form.endTime" type="datetime">
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 申请用水量：</div>
            <div class="content">
              <el-input
                v-model="Form.waterQuantity"
                clearable
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                placeholder="单位：m³"
              >
              </el-input>
              <div class="content-footer">m³</div>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 单价：</div>
            <div class="content">
              <el-input
                v-model="Form.price"
                disabled
                clearable
                maxlength="10"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                placeholder="单位：元"
              >
              </el-input>
              <div class="content-footer">元</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item"></div>
          <div class="search-item">
            <div class="label">金额：</div>
            <div class="content">
              <el-input
                v-model="Form.totalPrice"
                clearable
                disabled
                maxlength="10"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                placeholder="单位：元"
              >
              </el-input>
              <div class="content-footer">元</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.remark"
                clearable
                maxlength="300"
                type="textarea"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            @click="saveForm()"
            text="提交"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/water/water-apply/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  watch: {
    Form: {
      handler(val) {
        this.Form.totalPrice = val.price * val.waterQuantity;
      },
      deep: true,
    },
  },
  data() {
    return {
      arr: [
        {
          text: "用水管理",
          path: "/water/water-apply/page",
        },
        {
          text: "用水申请",
          path: null,
        },
      ],
      Form: {
        startTime: null,
        endTime: null,
        waterQuantity: null,
        price: null,
        totalPrice: null,
        fileInfo: null,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
    };
  },
  methods: {
    ...mapActions(["getParams", "AddWaterApply"]),
    saveForm() {
      if (this.Form.startTime === null || this.Form.startTime === "") {
        this.$message.warning("申请开始时间不能为空！");
        return;
      }
      if (this.Form.endTime === null || this.Form.endTime === "") {
        this.$message.warning("申请结束时间不能为空！");
        return;
      }
      if (this.Form.waterQuantity === null || this.Form.waterQuantity === "") {
        this.$message.warning("申请用水量不能为空！");
        return;
      }
      if (this.Form.waterQuantity < 1) {
        this.$message.warning("申请用水量不能小于1m³！");
        return;
      }
      this.AddWaterApply({
        _this: this,
        data: {
          ...this.Form,
          startTime: this.$utils.fixDateTime(this.Form.startTime),
          endTime: this.$utils.fixDateTime(this.Form.endTime),
        },
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("申请成功！");
            this.$router.push("/water/water-apply/page");
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 读取用水单价
      this.getParams({
        _this: this,
        name: "water-price",
      })
        .then((res) => {
          if (res !== false) {
            this.Form.price = res.body.content;
          }
        })
        .catch();
    });
  },
};
</script>
<style lang="less" scoped>
</style>