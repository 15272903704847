<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-user-cog" text="用户管理" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">姓名：</div>
          <div class="content">
            <el-input v-model="Query.nameLike" placeholder="" clearable>
            </el-input>
          </div>
        </div>
        <div class="search-item"></div>
        <div class="search-item"></div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-plus"
          class="btn"
          text="添加"
          @click="$router.push('/system/user/add')"
        />
        <Button
          icon="fa fa-trash-alt"
          class="btn"
          text="删除"
          style="margin-left: 10px"
          @click="del()"
        />
        <Button
          icon="fa fa-search"
          class="btn fr clearfix"
          text="查询"
          @click="queryData()"
        />
      </div>
      <div class="table-wrapper">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            prop="account"
            label="用户名"
            width="180"
          ></el-table-column>
          <el-table-column label="注册时间" width="200">
            <template slot-scope="scope">
              {{ scope.row.createTime | dateformat() }}
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="120"
          ></el-table-column>
          <el-table-column label="最后登录时间" width="200">
            <template slot-scope="scope">
              {{ scope.row.lastLoginTime | dateformat() }}
            </template>
          </el-table-column>
          <el-table-column
            prop="lastLoginIp"
            label="最后登录IP"
          ></el-table-column>
          <el-table-column prop="address" label="操作" width="160">
          <template slot-scope="scope">
            <span class="link operate-btn" @click="$router.push('/system/user/edit/' + scope.row.id)">
              <i class="fa fa-edit"></i>
              编辑
            </span>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="(val) => (Query.pageSize = val)"
          @current-change="(val) => (Query.pageIndex = val)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },

  watch: {
    "Query.pageIndex"() {
      this.queryData();
    },
    "Query.pageSize"() {
      this.queryData();
    },
  },
  data() {
    return {
      arr: [
        {
          text: "系统管理",
          path: null,
        },
        {
          text: "用户管理",
          path: null,
        },
      ],
      Query: {
        pageIndex: 1,
        pageSize: 10,
        nameLike: null,
      },
      tableData: [],
      total: 0,
      multipleSelection: [],
    };
  },
  methods: {
    ...mapActions(["getUserPage", "delUser"]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    queryData() {
      this.getUserPage({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
    del() {
      let arr = this.multipleSelection;
      if (arr.length === 0) {
        this.$message.warning("请选中您要删除的数据");
        return;
      }
      this.$msgbox
        .confirm(
          `此操作将永久删除选中的【${arr.length}】个用户, 是否继续?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          let newArr = [];
          arr.forEach((item) => {
            newArr.push(item.id);
          });
          this.delUser({
            _this: this,
            data: newArr,
          })
            .then((res) => {
              if (res !== false) {
                this.$message.success("删除成功！");
                this.queryData();
              }
            })
            .catch(() => {
              this.$message.error("删除失败！");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "用户取消操作",
          });
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 412px);
}
</style>