<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-list-ul" text="编辑引用水计划" />
    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        基础信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 年份：</div>
            <div class="content">
              <el-date-picker
                v-model="Form.year"
                type="year"
                placeholder="选择年"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 灌域：</div>
            <div class="content">
              <IrrigationSelect
                @change="(val) => (Form.irrigationDistrict.id = val)"
                :key="Form.irrigationDistrict.name"
                :value="Form.irrigationDistrict.name"
                @select="handleSelect"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">协会：</div>
            <div class="content">
              <Organization
                @change="(val) => (Form.organizationId = val)"
                :typeId="6"
                :key="Form.organizationName"
                :value="Form.organizationName"
                @select="handleSelect2"
              />
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 当年引水指标：</div>
            <div class="content">
              <el-input
                v-model="Form.diversionIndex"
                clearable
                maxlength="10"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                placeholder="单位：万m³"
              >
              </el-input>
              <div class="content-footer">万m³</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">
              <span class="star">*</span> 当年计划引水量：
            </div>
            <div class="content">
              <el-input
                v-model="Form.diversionPlanQuantity"
                clearable
                maxlength="10"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                placeholder="单位：万m³"
              >
              </el-input>
              <div class="content-footer">万m³</div>
            </div>
          </div>
          <div class="search-item">
            <div class="label">
              <span class="star">*</span> 当年实际引水量：
            </div>
            <div class="content">
              <el-input
                v-model="Form.diversionActualQuantity"
                clearable
                maxlength="10"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                placeholder="单位：万m³"
              >
              </el-input>
              <div class="content-footer">万m³</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 当年用水指标：</div>
            <div class="content">
              <el-input
                v-model="Form.usageIndex"
                clearable
                maxlength="10"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                placeholder="单位：万m³"
              >
              </el-input>
              <div class="content-footer">万m³</div>
            </div>
          </div>
          <div class="search-item">
            <div class="label">
              <span class="star">*</span> 当年计划用水量：
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              content="此项由系统计算"
              placement="left-start"
            >
              <div class="content">
                <el-input
                  v-model="Form.usagePlanQuantity"
                  clearable
                  maxlength="10"
                  type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                  @keydown.native="$utils.btKeyUp"
                  disabled
                >
                </el-input>
                <div class="content-footer">万m³</div>
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        月计划用水量
      </div>
      <div class="apply-main">
        <div class="row" v-for="item in 6" :key="item">
          <div class="search-item">
            <div class="label">
              <span class="star">*</span> {{ (item - 1) * 2 + 1 }}月计划用水量：
            </div>
            <div class="content">
              <el-input
                v-if="Form.waterPlanMonths.length > 0"
                v-model="Form.waterPlanMonths[(item - 1) * 2].quantity"
                clearable
                maxlength="10"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                placeholder="单位：万m³"
              >
              </el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="label">
              <span class="star">*</span> {{ item * 2 }}月计划用水量：
            </div>
            <div class="content">
              <el-input
                v-if="Form.waterPlanMonths.length > 0"
                v-model="Form.waterPlanMonths[(item - 1) * 2 + 1].quantity"
                clearable
                maxlength="10"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                placeholder="单位：万m³"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/water/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import IrrigationSelect from "@/components/IrrigationSelect";
import Title from "@/components/Title";
import Button from "@/components/Button";
import Organization from "@/components/Organization";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    IrrigationSelect,
    Title,
    Button,
    Organization
  },
  watch: {
    "Form.waterPlanMonths": {
      handler(val) {
        let total = 0;
        val.forEach((item) => {
          if (item.quantity > 0) {
            total += Number(item.quantity);
          }
        });
        this.Form.usagePlanQuantity = total;
      },
      deep: true,
    },
  },
  data() {
    return {
      arr: [
        {
          text: "引用水计划",
          path: "/basic/water/page",
        },
        {
          text: "编辑引用水计划",
          path: null,
        },
      ],
      Form: {
        year: null,
        irrigationDistrict: {
          id: null,
          name: null
        },
        organizationId: null,
        organizationName: null,
        diversionIndex: null,
        diversionPlanQuantity: null,
        diversionActualQuantity: null,
        usageIndex: null,
        usagePlanQuantity: null,
        waterPlanMonths: [],
      },
    };
  },
  methods: {
    ...mapActions(["EditWater", "WaterDetail"]),
    handleSelect(val) {
      this.Form.irrigationDistrict.id = val;
    },
    handleSelect2(val) {
      this.Form.organizationId = val;
    },
    // 提交表单
    saveForm() {
      if (this.Form.year === null || this.Form.year === "") {
        this.$message.warning("年份不能为空！");
        return;
      }
      if (
        this.Form.irrigationDistrict.id === null ||
        this.Form.irrigationDistrict.id === ""
      ) {
        this.$message.warning("灌域不能为空！");
        return;
      }
      if (
        this.Form.irrigationDistrict.id === 999
      ) {
        this.$message.warning("不存在您输入的灌域名称，请通过下拉点击填入灌域！");
        return;
      }
      if (
        this.Form.diversionIndex === null ||
        this.Form.diversionIndex === ""
      ) {
        this.$message.warning("当年引水指标不能为空！");
        return;
      }
      if (
        this.Form.diversionPlanQuantity === null ||
        this.Form.diversionPlanQuantity === ""
      ) {
        this.$message.warning("当年计划引水量不能为空！");
        return;
      }
      if (
        this.Form.diversionActualQuantity === null ||
        this.Form.diversionActualQuantity === ""
      ) {
        this.$message.warning("当年实际引水量不能为空！");
        return;
      }
      if (this.Form.usageIndex === null || this.Form.usageIndex === "") {
        this.$message.warning("当年用水指标不能为空！");
        return;
      }
      let bool = true;
      this.Form.waterPlanMonths.forEach((item) => {
        if (item.quantity === null || item.quantity === "") {
          if (bool) {
            this.$message.warning(item.month + "月计划用水量不能为空！");
          }
          bool = false;
        }
      });
      if (!bool) {
        return;
      }
      this.EditWater({
        _this: this,
        data: {
          ...this.Form,
          year:
            this.Form.year == null
              ? this.Form.year
              : this.$options.filters["dateformat"](this.Form.year, "YYYY"),
        },
        id: this.Form.id
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("编辑成功！");
            this.$router.push("/basic/water/page");
          }
        })
        .catch();
    },
    queryData(id) {
      this.WaterDetail({
        _this: this,
        id,
        data: this.Form,
      })
        .then((res) => {
          if (res !== false) {
            this.Form = {
              ...this.Form,
              ...res.body,
              year: res.body.year + "-01-01 00:00:00",
            };
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      let arr = this.$route.path.split("/");
      this.queryData(arr[arr.length - 1]);
    });
  },
};
</script>
<style lang="less" scoped>
</style>