<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-sitemap" text="灌域机构管理" />
    <div class="table-wrapper" style="padding: 0 20px">
      <el-table
        :data="tableData"
        style="width: 100%"
        height="100%"
        row-key="id"
        border
        lazy
        :load="load"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        :cell-style="$utils.cellStyle"
        :header-cell-style="$utils.headerCellStyle"
      >
        <el-table-column prop="orgranizationName" label="机构名称">
          <template slot-scope="scope">
            <span>
              {{ scope.row.orgranizationName }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="type.name" label="机构类型" width="180">
        </el-table-column>
        <el-table-column prop="address" label="操作" width="260">
          <template slot-scope="scope">
            <span class="link operate-btn" @click="addRow(scope.row)">
              <i class="fa fa-plus"></i>
              添加
            </span>
            <span class="link operate-btn" @click="editRow(scope.row)">
              <i class="fa fa-edit"></i>
              编辑
            </span>
            <!-- <span class="link operate-btn" @click="delRow(scope.row)">
                <i class="fa fa-trash-alt"></i>
                删除
              </span> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :title="Form.id === null ? '添加子机构' : '编辑机构'"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
    >
      <div class="search-item">
        <div class="label"><span class="star">*</span> 机构名称：</div>
        <div class="content">
          <el-input v-model="Form.name" clearable maxlength="32"> </el-input>
        </div>
      </div>
      <div class="search-item">
        <div class="label"><span class="star">*</span> 机构类型：</div>
        <div class="content">
          <el-select v-model="Form.type.id" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveForm()">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  data() {
    return {
      arr: [
        {
          text: "基础信息",
          path: null,
        },
        {
          text: "机构管理",
          path: null,
        },
      ],
      tableData: [],
      dialogVisible: false,
      Form: {
        id: null,
        name: null,
        type: {
          id: null,
        },
        parent: {
          id: null,
        },
      },
      options: [],
    };
  },
  methods: {
    ...mapActions([
      "OrganizationPage",
      "AddOrganization",
      "getDictionaryForType",
      "EditOrganization",
      "delOrganization",
    ]),
    // 异步加载子机构
    load(tree, treeNode, resolve) {
      this.OrganizationPage({
        _this: this,
        data: {
          parentId: tree.id,
        },
      })
        .then((res) => {
          if (res != false) {
            let arr = res.body.records.map((item) => {
              return {
                id: item.id,
                orgranizationName: item.name,
                children: [],
                parent: item.parent,
                hasChildren: item.hasChildren,
                type: item.type,
              };
            });
            resolve(arr);
          }
        })
        .catch();
    },
    // 编辑行
    editRow(item) {
      this.dialogVisible = true;
      this.Form = {
        ...this.Form,
        id: item.id,
        name: item.orgranizationName,
        parent: item.parent === null ? {id: 0} : item.parent,
      };
      this.Form.type.id = (item.type.id);
    },
    // 删除行
    delRow(item) {
      this.delOrganization({
        _this: this,
        data: [item.id],
      })
        .then((res) => {
          if (res != false) {
            this.$message.success("删除成功！");
            this.dialogVisible = false;
          }
        })
        .catch();
    },
    // 新增行
    addRow(item) {
      this.dialogVisible = true;
      (this.Form = {
        id: null,
        name: null,
        type: {
          id: null,
        },
        parent: {
          id: null,
        },
      }),
        (this.Form.parent.id = item.id);
    },
    saveForm() {
      if (this.Form.name === null || this.Form.name === "") {
        this.$message.warning("机构名称不能为空！");
        return;
      }
      if (this.Form.type.id === null || this.Form.type.id === "") {
        this.$message.warning("机构类型不能为空！");
        return;
      }
      if (this.Form.id === null) {
        this.AddOrganization({
          _this: this,
          data: this.Form,
        })
          .then((res) => {
            if (res != false) {
              this.$message.success("添加成功！");
              this.initTree();
              this.dialogVisible = false;
            }
          })
          .catch();
      } else {
        this.EditOrganization({
          _this: this,
          data: this.Form,
          id: this.Form.id,
        })
          .then((res) => {
            if (res != false) {
              this.$message.success("编辑成功！");
              this.initTree();
              this.dialogVisible = false;
            }
          })
          .catch();
      }
    },
    // 初始化第一层树
    initTree() {
      this.tableData = [];
      (this.Form = {
        id: null,
        name: null,
        type: {
          id: null,
        },
        parent: {
          id: null,
        },
      }),
        this.OrganizationPage({
          _this: this,
          data: {},
        })
          .then((res) => {
            if (res != false) {
              this.tableData = res.body.records.map((item) => {
                return {
                  id: item.id,
                  orgranizationName: item.name,
                  children: [],
                  parent: item.parent,
                  hasChildren: true,
                  type: item.type,
                };
              });
              this.tableData = this.tableData.filter(
                (item) => item.parent === null
              );
            }
          })
          .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 获取机构类型下拉选项
      this.options = this.allOptions.filter((item) => item.type === "OrganizationType");
      if (this.options.length > 0) {
        this.options = this.options[0].arr;
      } else {
        // 没有就去查
        this.getDictionaryForType({
          _this: this,
          type: "OrganizationType",
        })
          .then((res) => {
            if (res !== false) {
              res.body.options.forEach((item) => {
                this.options.push({
                  value: item.id + "",
                  label: item.name,
                });
              });
            }
          })
          .catch();
      }
      this.initTree();
    });
  },
};
</script>
<style lang="less" scoped>
.operate-btn {
  margin: 0 10px;
}
.table-wrapper {
  height: calc(100vh - 250px);
}
.search-item {
  flex: 1;
  margin-bottom: 10px;
  height: 40px;
  line-height: 40px;
  div {
    display: inline-block;
  }
  .label {
    color: #333;
    font-weight: 600;
    width: 140px;
    text-align: right;
    vertical-align: top;
  }
  .content {
    width: calc(100% - 140px);
  }
}
</style>