<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-dharmachakra" text="编辑灌域" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 灌域名称：</div>
            <div class="content">
              <el-input v-model="Form.name" clearable maxlength="16">
              </el-input>
            </div>
          </div>
          <div class="search-item"></div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 灌域类别：</div>
            <div class="content">
              <el-select v-model="Form.type.id" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 管理机构：</div>
            <div class="content">
              <Organization
                :value="Form.organization.name"
                :key="Form.organization.name"
                @change="val => Form.organization.id = val"
                @select="handleSelect"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 设计灌溉面积：</div>
            <div class="content">
              <el-input
                v-model="Form.designIrrigationArea"
                clearable
                maxlength="32"
                placeholder="单位㎡"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">㎡</div>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 渠首取水位置：</div>
            <div class="content">
              <el-input
                v-model="Form.canalHeaderIntakeLocation"
                clearable
                maxlength="32"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 渠首设计流量：</div>
            <div class="content">
              <el-input
                v-model="Form.canalHeaderDesignFlow"
                clearable
                maxlength="32"
                placeholder="单位m³/s"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">m³/s</div>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 总干渠长度：</div>
            <div class="content">
              <el-input
                v-model="Form.canalLength"
                clearable
                maxlength="32"
                placeholder="单位m"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">m</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 灌溉范围：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.irrigationScope"
                clearable
                maxlength="300"
                type="textarea"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 80px">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 灌域围栏：</div>
            <div class="content">
              <div class="amap" id="amap"></div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 410px">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.remark"
                clearable
                maxlength="300"
                type="textarea"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/Irrigation/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import Organization from "@/components/Organization";
import { mapActions, mapState } from "vuex";
import cover from "@/assets/cover.png";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    Organization,
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  watch: {
    "Form.centerLocations": {
      handler(val) {
        if (val.length > 0) {
          this.setMarker(val[0]);
        } else {
          // 获取已有覆盖物并清除
          let arr = this.map.getAllOverlays("marker");
          if (arr.length > 0) {
            this.map.remove(arr);
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      arr: [
        {
          text: "灌域管理",
          path: "/basic/Irrigation/page",
        },
        {
          text: "编辑灌域",
          path: null,
        },
      ],
      Form: {
        id: null,
        name: null,
        type: {
          id: null,
          name: null,
        },
        organization: {
          id: null,
          name: null,
        },
        designIrrigationArea: null,
        canalHeaderIntakeLocation: null,
        canalHeaderDesignFlow: null,
        canalLength: null,
        irrigationScope: null,
        fences: [],
        remark: null,
        centerLocations: [],
      },
      options: [],
      btnType: true,
      map: null,
      tempArr: [],
      polyEditor: null,
    };
  },
  methods: {
    ...mapActions(["IrrigationDetail", "EditIrrigation", "getDictionaryForType"]),
    handleSelect(val) {
      this.Form.organization.id = val;
    },
    // 创建地图
    createMap() {
      let _this = this;
      // 构造地图对象的方法一：使用地图容器ID创建
      this.map = new AMap.Map("amap", {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 16, //设置地图显示的缩放级别
        //center: _this.Form.lnglat, //设置地图中心点坐标
        mapStyle: "amap://styles/normal", //设置地图的显示样式
        viewMode: "2D", //设置地图模式
        //lang: "zh_cn", //设置地图语言类型
      });
      this.map.on("complete", function () {
        // 地图图块加载完成后触发
        _this.drawPolygon();
      });
      this.map.on("click", (e) => {
         _this.Form.centerLocations = [[e.lnglat.lng, e.lnglat.lat]];
      })
    },
    // 绘制Marker
    setMarker(lnglat) {
      // 获取已有覆盖物并清除
      let arr = this.map.getAllOverlays("marker");
      if (arr.length > 0) {
        this.map.remove(arr);
      }
      var marker = new AMap.Marker({
        icon: cover, //图片ip
        imageSize: "20px",
        position: lnglat,
        offset: new AMap.Pixel(-20, -20),
        // 设置是否可以拖拽
        draggable: false,
        cursor: "pointer",
        // 设置拖拽效果
        raiseOnDrag: true,
      });
      marker.setMap(this.map);
    },
    // 绘制多边形
    drawPolygon() {
      var _this = this;
      var polygon = new AMap.Polygon({
        path: _this.tempArr,
      });
      this.map.add(polygon);
      this.map.setFitView();
      this.polyEditor = new AMap.PolygonEditor(this.map);
      this.polyEditor.addAdsorbPolygons([polygon]);
      this.polyEditor.on("add", function (data) {
        var polygon = data.target;
        _this.polyEditor.addAdsorbPolygons(polygon);
      });
      polygon.on("dblclick", () => {
        _this.polyEditor.setTarget(polygon);
        _this.polyEditor.open();
      });
      this.polyEditor.on("end", function (event) {
        _this.tempArr = event.target._opts.path;
        // event.target 即为编辑后的多边形对象
      });
    },
    // 提交表单
    saveForm() {
      this.polyEditor.close();
      if (this.Form.name === null || this.Form.name === "") {
        this.$message.warning("灌域名称不能为空！");
        return;
      }
      if (this.Form.type.id === null || this.Form.type.id === "") {
        this.$message.warning("灌域类别不能为空！");
        return;
      }
      if (
        this.Form.organization.id === null ||
        this.Form.organization.id === ""
      ) {
        this.$message.warning("管理机构不能为空！");
        return;
      }
      if (
        this.Form.organization.id === 999
      ) {
        this.$message.warning("不存在您输入的机构名称，请通过下拉点击填入管理机构！");
        return;
      }
      if (
        this.Form.designIrrigationArea === null ||
        this.Form.designIrrigationArea === ""
      ) {
        this.$message.warning("设计灌溉面积不能为空！");
        return;
      }
      if (
        this.Form.canalHeaderIntakeLocation === null ||
        this.Form.canalHeaderIntakeLocation === ""
      ) {
        this.$message.warning("渠首取水位置不能为空！");
        return;
      }
      if (
        this.Form.canalHeaderDesignFlow === null ||
        this.Form.canalHeaderDesignFlow === ""
      ) {
        this.$message.warning("渠首设计流量不能为空！");
        return;
      }
      if (this.Form.canalLength === null || this.Form.canalLength === "") {
        this.$message.warning("总干渠长度不能为空！");
        return;
      }
      if (this.Form.irrigationScope === null || this.Form.irrigationScope === "") {
        this.$message.warning("灌溉范围不能为空！");
        return;
      }
      if (this.Form.centerLocations.length < 1) {
        this.$message.warning(
          "灌域中心点不能为空！点击地图任意地方可选择中心点"
        );
        return;
      }
      // 数据特殊转换
      this.Form.fences = [];
      let tArr = [];
      this.tempArr.forEach((item) => {
        if (item.lng) {
          tArr = [item.lng, item.lat];
          this.Form.fences.push(tArr);
        } else {
          this.Form.fences.push(item);
        }
      });
      if (
        !(
          this.Form.fences[0][0] == this.Form.fences[this.Form.fences.length - 1][0] &&
          this.Form.fences[0][1] == this.Form.fences[this.Form.fences.length - 1][1]
        )
      ) {
        this.Form.fences.push(this.Form.fences[0]);
      }

      this.EditIrrigation({
        _this: this,
        id: this.Form.id,
        data: this.Form,
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("编辑成功！");
            this.$router.push("/basic/Irrigation/page");
          }
        })
        .catch();
    },
    queryData(id) {
      this.IrrigationDetail({
        _this: this,
        id,
        data: this.Form,
      })
        .then((res) => {
          if (res !== false) {
            this.Form = res.body;
            this.Form.fences.forEach((item) => {
              this.tempArr.push(item);
            });
            this.createMap();
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      let arr = this.$route.path.split("/");
      this.queryData(arr[arr.length - 1]);
      // 获取灌域类别下拉选项
      // 是否已有缓存
      this.options = this.allOptions.filter(
        (item) => item.type === "IrrigationDistrictType"
      );
      if (this.options.length > 0) {
        this.options = this.options[0].arr;
      } else {
        // 没有就去查
        this.getDictionaryForType({
          _this: this,
          type: "IrrigationDistrictType",
        })
          .then((res) => {
            if (res !== false) {
              res.body.options.forEach((item) => {
                this.options.push({
                  value: item.id + "",
                  label: item.name,
                });
              });
            }
          })
          .catch();
      }
    });
  },
};
</script>
<style lang="less" scoped>
.amap {
  height: 400px;
  width: 100%;
  margin-top: 10px;
}
</style>