<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-retweet" text="水权交易详情" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">买方机构：</div>
            <div class="content">
              {{ Form.buyer.name }}
            </div>
          </div>
          <div class="search-item">
            <div class="label">卖方机构：</div>
            <div class="content">
              {{ Form.seller.name }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">交易时间：</div>
            <div class="content">
              {{ Form.transactionTime | dateformat() }}
            </div>
          </div>
          <div class="search-item">
            <div class="label">交易水量：</div>
            <div class="content">{{ Form.waterQuantity }}m³</div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">单价：</div>
            <div class="content">{{ Form.price }}元</div>
          </div>
          <div class="search-item">
            <div class="label">经办人：</div>
            <div class="content">
              {{ Form.operator }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              {{ Form.remark }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">附件：</div>
            <div class="content link">
              <span
                @click="downloadFile(Form.attachments[0])"
                v-if="Form.attachments != null && Form.attachments.length > 0"
                >{{ Form.attachments[0].filename }}</span
              >
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/water/transaction/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "用水管理",
          path: null,
        },
        {
          text: "水权交易",
          path: "/water/transaction/page",
        },
        {
          text: "水权交易详情",
          path: null,
        },
      ],
      Form: {
        seller: {
          name: null,
        },
        buyer: {
          name: null,
        },
        transactionTime: null,
        waterQuantity: null,
        price: null,
        operator: null,
        remark: null,
      },
    };
  },
  methods: {
    ...mapActions(["TransactionDetail", "download"]),
    downloadFile({ uid, filename }) {
      this.download({
        _this: this,
        id: uid,
        filename,
      })
        .then((res) => {
          if (res != false) {
            this.$message.success("下载成功！");
          }
        })
        .catch();
    },
    queryData(id) {
      this.TransactionDetail({
        _this: this,
        id,
        data: this.Form,
      })
        .then((res) => {
          if (res !== false) {
            this.Form = {
              ...this.Form,
              ...res.body,
            };
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      let arr = this.$route.path.split("/");
      this.queryData(arr[arr.length - 1]);
    });
  },
};
</script>