<template>
    <div class="title">
        <div class="title-main">
            <i :class="icon" style="margin-right: 10px;"></i>
            {{text}}
        </div>
    </div>
</template>
<script>
export default {
    props: ["icon", "text"]
}
</script>
<style lang="less" scoped>
.title {
    width: 100%;
    height: 80px;
    .title-main {
        width: 320px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-weight: bolder;
        margin: 0 auto;
        margin-top: 20px;
        color: rgb(0, 185, 231);
        font-size: 24px;
        border-bottom: 1px solid #ddd;
    }
}
</style>