<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-fill-drip" text="闸门统计" />
    <div class="monitor-top">
      <div class="top-item apply-body">
        <div class="apply-header">
          <i class="fa fa-cloud-rain" style="margin: 0 10px"></i>
          按月统计
        </div>
        <div class="search-div" style="border: none">
          <div class="search-item">
            <div class="label">闸门：</div>
            <div class="content" style="width: 360px">
              <GateSelect
                @change="(val) => (Query1.gate.id = val)"
                @select="handleSelect1"
                :gateId="Query1.gate.id"
              />
            </div>
          </div>
        </div>
        <div>
          <Button
            icon="fa fa-search"
            class="btn"
            text="查询"
            @click="drawChart1"
          />
          <!-- <Button
            icon="fa fa-list"
            class="btn fr clearfix"
            @click="Rain.active = 2"
            :class="Rain.active === 2 ? '' : 'not-active'"
            text="列表"
          />
          <Button
            icon="fa fa-chart-bar"
            class="btn fr clearfix"
            @click="Rain.active = 1"
            :class="Rain.active === 1 ? '' : 'not-active'"
            text="图表"
          /> -->
        </div>
        <div class="chart1" ref="chart1" v-show="Query1.active === 1"></div>

        <div class="table-div" v-show="Query1.active === 2">
          <el-table
            :data="rainData"
            border
            style="width: 100%; margin-top: 20px"
            height="360px"
          >
            <el-table-column prop="x4" label="时间"></el-table-column>
            <el-table-column prop="x4" label="水位(m)"></el-table-column>
            <el-table-column prop="x4" label="流量(m³/s)"></el-table-column>
          </el-table>
          <div style="text-align: center; margin: 5px 0">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :total="30"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="top-item apply-body" style="margin-left: 20px">
        <div class="apply-header">
          <i class="fa fa-cloud-rain" style="margin: 0 10px"></i>
          历史同期
        </div>
        <div class="search-div" style="border: none">
          <div class="search-item">
            <div class="label">闸门：</div>
            <div class="content">
              <GateSelect
                @change="(val) => (Query2.gate.id = val)"
                @select="handleSelect2"
                :gateId="Query2.gate.id"
              />
            </div>
          </div>
          <div class="search-item">
            <div class="label">开始年份：</div>
            <div class="content">
              <el-date-picker
                v-model="Query2.startYear"
                type="year"
                placeholder=""
              >
              </el-date-picker>
            </div>
          </div>
          <div class="search-item">
            <div class="label">结束年份：</div>
            <div class="content">
              <el-date-picker
                v-model="Query2.endYear"
                type="year"
                placeholder=""
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div>
          <Button
            icon="fa fa-search"
            class="btn"
            text="查询"
            @click="drawChart2"
          />
          <!-- <Button
            icon="fa fa-list"
            class="btn fr clearfix"
            @click="Water.active = 2"
            :class="Water.active === 2 ? '' : 'not-active'"
            text="列表"
          />
          <Button
            icon="fa fa-chart-bar"
            class="btn fr clearfix"
            @click="Water.active = 1"
            :class="Water.active === 1 ? '' : 'not-active'"
            text="图表"
          /> -->
        </div>
        <div class="chart2" ref="chart2" v-show="Query2.active === 1"></div>
        <div class="table-div" v-show="Query2.active === 2">
          <el-table
            :data="waterData"
            border
            style="width: 100%; margin-top: 20px"
            height="360px"
          >
            <el-table-column prop="x4" label="时间"></el-table-column>
            <el-table-column prop="x4" label="水位(m)"></el-table-column>
            <el-table-column prop="x4" label="流量(m³/s)"></el-table-column>
          </el-table>
          <div style="text-align: center; margin: 5px 0">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :total="30"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import GateSelect from "@/components/GateSelect";
import * as echarts from "echarts";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    GateSelect,
  },
  computed: {
    ...mapState(["now"]),
    marginLeft() {
      let left = `calc(-${25 * this.index}vw + ${39 * this.index}px)`;
      return left;
    },
  },
  data() {
    return {
      arr: [
        {
          text: "统计分析",
          path: null,
        },
        {
          text: "闸门统计",
          path: null,
        },
      ],
      Query1: {
        time: [],
        active: 1,
        gate: {
          id: 1,
        },
      },
      Query2: {
        time: [],
        active: 1,
        gate: {
          id: 1,
        },
        startYear: "2020",
        endYear: "2021",
      },
      index: 0,
      rainData: [],
      waterData: [],
    };
  },
  methods: {
    ...mapActions(["gateMonth", "gateYear"]),
    handleSelect1(val) {
      this.Query1.gate.id = val;
    },
    handleSelect2(val) {
      this.Query2.gate.id = val;
    },
    // 切换上下图
    prevImg() {
      this.index--;
      this.index = this.index < 0 ? 0 : this.index;
    },
    nextImg() {
      this.index++;
      this.index = this.index > 9 ? 9 : this.index;
    },
    drawChart1() {
      if (this.Query1.gate.id === null) {
        this.$message.error("请先选择闸门！");
        return;
      }
      this.gateMonth({
        _this: this,
        id: this.Query1.gate.id,
      })
        .then((res) => {
          if (res != false) {
            let xaxis = res.body.xaxis;
            let series = res.body.series;
            series = series.map((item) => {
              return {
                type: "bar",
                showBackground: true,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#83bff6" },
                    { offset: 0.5, color: "#188df0" },
                    { offset: 1, color: "#188df0" },
                  ]),
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#2378f7" },
                      { offset: 0.7, color: "#2378f7" },
                      { offset: 1, color: "#83bff6" },
                    ]),
                  },
                },
                ...item,
              };
            });
            var echartsDom = echarts.init(this.$refs.chart1);
            echartsDom.setOption({
              title: {
                text: "放水量月统计图",
                left: "center",
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  label: {
                    backgroundColor: "#666",
                  },
                },
              },
              xAxis: {
                data: xaxis,
              },
              yAxis: {
                name: "单位(万m³)",
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    color: "#999",
                  },
                },
              },
              grid: {
                // 边距
                x: 10, // 左
                y: 40, // 上
                x2: 10, // 右
                y2: 10, // 下
                containLabel: true,
              },
              dataZoom: [
                {
                  type: "inside",
                },
              ],
              series,
            });
            var zoomSize = 6;
            echartsDom.on("click", function (params) {
              echartsDom.dispatchAction({
                type: "dataZoom",
                startValue:
                  dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
                endValue:
                  dataAxis[
                    Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
                  ],
              });
            });
          }
        })
        .catch();
    },
    drawChart2() {
      if (this.Query2.gate.id === null) {
        this.$message.error("请先选择闸门！");
        return;
      }
      if (this.Query2.startYear === null || this.Query2.startYear === "") {
        this.$message.error("请选择开始年份呢！");
        return;
      }
      if (this.Query2.endYear === null || this.Query2.endYear === "") {
        this.$message.error("请选择结束年份！");
        return;
      }
      this.gateYear({
        _this: this,
        id: this.Query2.gate.id,
        data: {
          startYear:
            this.Query2.startYear == null
              ? this.Query2.startYear
              : this.$options.filters["dateformat"](
                  this.Query2.startYear,
                  "YYYY"
                ),
          endYear:
            this.Query2.endYear == null
              ? this.Query2.endYear
              : this.$options.filters["dateformat"](
                  this.Query2.endYear,
                  "YYYY"
                ),
        },
      })
        .then((res) => {
          if (res != false) {
            let xaxis = res.body.xaxis;
            let series = res.body.series;
            series = series.map((item) => {
              return {
                type: "bar",
                showBackground: true,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#83bff6" },
                    { offset: 0.5, color: "#188df0" },
                    { offset: 1, color: "#188df0" },
                  ]),
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#2378f7" },
                      { offset: 0.7, color: "#2378f7" },
                      { offset: 1, color: "#83bff6" },
                    ]),
                  },
                },
                ...item,
              };
            });
            var echartsDom = echarts.init(this.$refs.chart2);
            echartsDom.setOption({
              title: {
                text: "历史同期放水量统计图",
                left: "center",
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  label: {
                    backgroundColor: "#666",
                  },
                },
              },
              xAxis: {
                data: xaxis,
              },
              yAxis: {
                name: "单位(万m³)",
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    color: "#999",
                  },
                },
              },
              grid: {
                // 边距
                x: 10, // 左
                y: 40, // 上
                x2: 10, // 右
                y2: 10, // 下
                containLabel: true,
              },
              dataZoom: [
                {
                  type: "inside",
                },
              ],
              series,
            });
            var zoomSize = 6;
            echartsDom.on("click", function (params) {
              echartsDom.dispatchAction({
                type: "dataZoom",
                startValue:
                  dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
                endValue:
                  dataAxis[
                    Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
                  ],
              });
            });
          }
        })
        .catch();
    },
  },
  mounted() {
    this.drawChart1();
    this.drawChart2();
  },
};
</script>
<style lang="less" scoped>
.label {
  width: 75px !important;
}
.not-active {
  background-color: rgba(196, 196, 196, 1);
  &:hover {
    background-color: rgba(196, 196, 196, 1);
  }
}
.monitor-top {
  height: 400px;
  padding: 0 20px;
  .top-item {
    display: inline-block;
    width: calc(50% - 10px);
    vertical-align: top;
    .chart1,
    .chart2 {
      height: 400px;
      margin-top: 20px;
    }
  }
}
.station-info {
  margin-top: 200px;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  .info-item {
    width: 360px;
    display: inline-block;
    vertical-align: top;
    > div {
      display: inline-block;
    }
    .info-l {
      text-align: right;
      width: 150px;
      font-weight: bold;
      color: #000;
      margin-top: 15px;
    }
  }
}
.carousel {
  padding: 0 85px;
  height: 24vh;
  position: relative;
  margin: 30px 0;
  overflow: hidden;
  .carousel-wrapper {
    height: 100%;
    width: calc(100% - 60px);
    transform: translateX(-18px);
    transition: all 0.4s linear;
    .carousel-item {
      display: inline-block;
      height: 100%;
      width: calc(25vw - 58px);
      margin: 0 10px;
      border: 1px solid #ddd;
      box-shadow: 0 0 3px #ddd;
      .item-img {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 10px;
      }
    }
  }
  .carousel-btn {
    position: absolute;
    height: 100%;
    background-color: #fff;
    top: 0;
    i {
      font-size: 48px;
      color: #409efb;
      cursor: pointer;
      margin-top: calc(12vh - 20px);
    }
  }
  .carousel-l {
    left: 0;
    padding-left: 25px;
  }
  .carousel-r {
    right: 0;
    padding-right: 25px;
  }
}
</style>