<template>
  <div class="page detail">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-file-signature" text="用水申请详情" />
    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        申请信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">申请人：</div>
            <div class="content" v-if="Form.id">{{ Form.createUser.name }}</div>
          </div>
          <div class="search-item">
            <div class="label">申请时间：</div>
            <div class="content" v-if="Form.id">
              {{ Form.createTime | dateformat() }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">申请开始时间：</div>
            <div class="content" v-if="Form.id">
              {{ Form.startTime | dateformat() }}
            </div>
          </div>
          <div class="search-item">
            <div class="label">申请结束时间：</div>
            <div class="content" v-if="Form.id">
              {{ Form.endTime | dateformat() }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">申请用水量：</div>
            <div
              class="content"
              v-if="Form.id"
              style="font-weight: bold; color: #000"
            >
              {{ Form.waterQuantity }}m³
            </div>
          </div>
          <div class="search-item"></div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content" v-if="Form.id">{{ Form.remark }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        审批信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">放水闸门：</div>
            <div class="content" v-if="Form.id && Form.device != null">
              {{ Form.device.name }}
            </div>
          </div>
          <div class="search-item">
            <div class="label">审批状态：</div>
            <div class="content" v-if="Form.id">
              {{ Form.approvalState | toggleState }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">审批时间：</div>
            <div class="content" v-if="Form.id">
              {{ Form.approvalTime | dateformat() }}
            </div>
          </div>
          <div class="search-item">
            <div class="label">审批人：</div>
            <div class="content" v-if="Form.id">
              {{ Form.approvalUser.name }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">审批意见：</div>
            <div class="content" v-if="Form.id">
              {{ Form.approvalComments }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        放水信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">放水开始时间：</div>
            <div class="content"></div>
          </div>
          <div class="search-item">
            <div class="label">放水结束时间：</div>
            <div class="content"></div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">放水量：</div>
            <div class="content" style="font-weight: bold; color: #000"></div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-retweet"
            class="btn"
            text="调度"
            v-if="Form.approvalState === 9"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="
              $router.push(
                $route.query.uid ? '/todo' : '/water/water-apply/page'
              )
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "用水管理",
          path: "/water/water-apply/page",
        },
        {
          text: "用水申请详情",
          path: null,
        },
      ],
      Form: {},
    };
  },
  methods: {
    ...mapActions(["WaterApplyDetail", "WaterApplyDetailByUid"]),
  },
  mounted() {
    this.$nextTick(() => {
      let id = this.$route.params.id;
      let uid = this.$route.query.uid;
      if (uid) {
        this.WaterApplyDetailByUid({
          _this: this,
          uid,
        })
          .then((res) => {
            if (res != false) {
              this.Form = res.body;
            }
          })
          .catch();
      } else {
        this.WaterApplyDetail({
          _this: this,
          id,
        })
          .then((res) => {
            if (res != false) {
              this.Form = res.body;
            }
          })
          .catch();
      }
    });
  },
};
</script>