import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=aeed50c2&scoped=true&"
import script from "./Page.vue?vue&type=script&lang=js&"
export * from "./Page.vue?vue&type=script&lang=js&"
import style0 from "./Page.vue?vue&type=style&index=0&id=aeed50c2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeed50c2",
  null
  
)

export default component.exports