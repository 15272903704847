<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-user-plus" text="用水户申请审批" />
    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        申请信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">协会：</div>
            <div class="content" v-if="Form.id">{{Form.organization.name}}</div>
          </div>
          <div class="search-item">
            <div class="label">灌域：</div>
            <div class="content" v-if="Form.id">{{Form.irrigationDistrict.name}}</div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">放水闸门：</div>
            <div class="content" v-if="Form.id && Form.device != null">
              <span v-for="(item, index) in Form.device" :key="index">
                {{item.name}}  
              </span>
            </div>
          </div>
          <div class="search-item">
            <div class="label">申请人：</div>
            <div class="content" v-if="Form.id">{{Form.createUser.name}}</div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">手机号：</div>
            <div class="content" v-if="Form.id" style="font-weight: bold; color: #000">
              {{Form.mobile}}
            </div>
          </div>
          <div class="search-item">
            
          </div>
        </div>
      </div>
    </div>

    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        审批信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 是否同意：</div>
            <div class="content">
              <el-radio v-model="Form.approvalState" :label="9">同意</el-radio>
              <el-radio v-model="Form.approvalState" :label="8"
                >不同意</el-radio
              >
            </div>
          </div>
          <div class="search-item">
            
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 审批意见：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.approvalComments"
                clearable
                maxlength="300"
                type="textarea"
                style="margin-top: 15px;"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="btn-list" style="transform: translateY(15px)">
          <Button icon="fa fa-retweet" class="btn" text="确定" @click="saveForm()" />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push($route.query.uid ? '/todo' : '/water/water-user/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "用水管理",
          path: "/water/water-user/page",
        },
        {
          text: "用水户申请审批",
          path: null,
        },
      ],
      Form: {
        approvalState: null,
        approvalComments: null
      },
    };
  },
  methods: {
    ...mapActions(["WaterUserDetail", "ApprovalWaterUser", "WaterUserDetailByUid"]),
    saveForm() {
      if (this.Form.approvalState === null || this.Form.approvalState === "") {
        this.$message.warning("是否同意不能为空！");
        return;
      }
      if (this.Form.approvalComments === null || this.Form.approvalComments === "") {
        this.$message.warning("审批意见不能为空！");
        return;
      }
      this.ApprovalWaterUser({
        _this: this,
        data: this.Form,
        id: this.Form.id
      }).then(res => {
        if (res != false) {
          this.$message.success("审批成功！")
          this.$router.push("/water/water-user/page")
        }
      }).cacth()
    }
  },
  mounted() {
    this.$nextTick(() => {
      let id = this.$route.params.id;
      let uid = this.$route.query.uid;
      if (uid) {
        this.WaterUserDetailByUid({
          _this: this,
          uid,
        })
          .then((res) => {
            if (res != false) {
              this.Form = res.body;
            }
          })
          .catch();
      } else {
        this.WaterUserDetail({
          _this: this,
          id
        })
          .then((res) => {
            if (res != false) {
              this.Form = res.body;
            }
          })
          .catch();
      }
    });
  },
};
</script>
<style lang="less" scoped>
.content {
  color: #777;
}
.apply-main {
  padding: 20px 0;
}
.search-item {
  margin-bottom: 0 !important;
}
.row {
  margin-top: 0px;
}
</style>