<template>
  <div class="bread">
    <div class="line"></div>
    <span class="bread-item" v-for="(item, index) in arr" :key="index">
      <span
        :class="item.path != null ? 'link' : ''"
        @click="handleClick(item.path)"
        >{{ item.text }}</span
      >
      <i
        class="fa fa-angle-double-right"
        style="margin: 0 10px"
        v-show="index + 1 < arr.length"
      ></i>
    </span>
    <span class="time">
      <i class="fa fa-calendar-alt"></i>
      {{now | dateformat("YYYY/MM/DD")}}
    </span>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["arr"],
  computed: {
    ...mapState(["now"]),
  },
  methods: {
    handleClick(path) {
      if (path && path != null) {
        this.$router.push(path);
      }
    },
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.bread {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: rgb(242, 242, 242);
  position: relative;
  padding-left: 30px;
  .line {
    width: 3px;
    height: 16px;
    background-color: rgb(0, 185, 231);
    position: absolute;
    left: 20px;
    top: 13px;
  }
  .bread-item {
    font-weight: bold;
  }
  .time {
    float: right;
    margin-right: 20px;
    color: #999;
  }
}
</style>