<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-tools" text="参数设置" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">类别：</div>
          <div class="content">
            <el-input
              v-model="Query.categoryLike"
              clearable
              maxlength="50"
            ></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="label">名称：</div>
          <div class="content">
            <el-input
              v-model="Query.nameLike"
              clearable
              maxlength="50"
            ></el-input>
          </div>
        </div>
        <div class="search-item"></div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-search"
          class="btn fr clearfix"
          text="查询"
          @click="queryData()"
        />
      </div>
      <div class="table-wrapper">
        <el-table :data="tableData" border style="width: 100%" height="100%">
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column prop="category" label="类别"></el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="content"
            label="值"
            width="200"
          ></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <span
                class="link"
                v-if="scope"
                @click="toEditPage(scope.row)"
              >
                <i class="fa fa-edit"></i>
                编辑
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="val => Query.pageSize = val"
          @current-change="val => Query.pageIndex = val"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  watch: {
    "Query.pageIndex"() {
      this.queryData();
    },
    "Query.pageSize"() {
      this.queryData();
    },
  },
  data() {
    return {
      arr: [
        {
          text: "系统管理",
          path: null,
        },
        {
          text: "参数设置",
          path: null,
        },
      ],
      Query: {
          categoryLike: null,
          nameLike: null,
          pageIndex: 1,
          pageSize: 10
      },
      tableData: [],
      total: 0
    };
  },
  methods: {
    ...mapActions(["getParamsPage"]),
    queryData() {
      this.getParamsPage({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount
          }
        })
        .catch();
    },
    toEditPage(item) {
        this.$router.push({
            path: '/system/params/edit/' + item.id,
            query: item
        })
    }
  },
  mounted() {
    this.$nextTick(() => {
        this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 412px);
  margin-top: 40px;
}
</style>