<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-hotdog" text="渠道管理" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">渠道名称：</div>
          <div class="content">
            <el-input
              v-model="Query.nameLike"
              clearable
              maxlength="50"
            ></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="label">所属灌域：</div>
          <div class="content">
            <IrrigationSelect @change="val => Query.irrigationDistrictId = val" @select="handleSelect" />
          </div>
        </div>
        <div class="search-item">
          <div class="label">渠道级别：</div>
          <div class="content">
            <el-select v-model="Query.levelId" clearable placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"             
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-plus"
          class="btn"
          text="添加"
          @click="$router.push('/basic/channel/add')"
        />
        <Button
          icon="fa fa-trash-alt"
          class="btn"
          text="删除"
          style="margin-left: 10px"
          @click="del()"
        />
        <Button icon="fa fa-search" class="btn fr clearfix" text="查询" @click="queryData()" />
      </div>
      <div class="table-wrapper">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="渠道名称">
            <template slot-scope="scope">
              <span
                class="link"
                @click="$router.push('/basic/channel/detail/' + scope.row.id)"
                >{{ scope.row.name }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="所属灌域"
            width="160"
          >
          <template slot-scope="scope">
            <span v-for="item in scope.row.irrigationDistrictList" :key="item.id">{{item.name}} </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="level.name"
            label="渠道级别"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="designFlow"
            label="设计流量(m³/s)"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="length"
            label="渠道长度(m)"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="runningState.name"
            label="运行状况"
            width="120"
          ></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <span
                class="link"
                v-if="scope"
                @click="$router.push('/basic/channel/edit/' + scope.row.id)"
              >
                <i class="fa fa-edit"></i>
                编辑
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="val => Query.pageSize = val"
          @current-change="val => Query.pageIndex = val"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import IrrigationSelect from "@/components/IrrigationSelect";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    IrrigationSelect
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  watch: {
    "Query.pageIndex"() {
      this.queryData();
    },
    "Query.pageSize"() {
      this.queryData();
    },
  },
  data() {
    return {
      arr: [
        {
          text: "基础信息",
          path: null,
        },
        {
          text: "渠道管理",
          path: null,
        },
      ],
      Query: {
        nameLike: "",
        irrigationDistrictId: null,
        levelId: null,
        pageIndex: 1,
        pageSize: 10,
      },
      options: [],
      tableData: [],
      total: 0,
      multipleSelection: [],
    };
  },
  methods: {
    ...mapActions(["ChannelPage", "DelChannel", "getDictionaryForType"]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelect(val) {
      this.Query.irrigationDistrictId = val
    },
    queryData() {
      this.ChannelPage({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
    del() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning("请选中您要删除的行！");
        return;
      }
      let arr = this.multipleSelection.map((item) => item.id);
      this.DelChannel({
        _this: this,
        data: arr,
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("删除成功！");
            this.queryData();
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 获取渠道级别下拉选项
      // 是否已有缓存
      this.options = this.allOptions.filter(
        (item) => item.type === "CanalLevel"
      );
      if (this.options.length > 0) {
        this.options = this.options[0].arr;
      } else {
        // 没有就去查
        this.getDictionaryForType({
          _this: this,
          type: "CanalLevel",
        })
          .then((res) => {
            if (res !== false) {
              res.body.options.forEach((item) => {
                this.options.push({
                  value: item.id + "",
                  label: item.name,
                });
              });
            }
          })
          .catch();
      }

      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 412px);
}
</style>