<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-hotdog" text="添加渠道" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 渠道名称：</div>
            <div class="content">
              <el-input v-model="Form.name" clearable maxlength="32">
              </el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 渠道级别：</div>
            <div class="content">
              <el-select v-model="Form.level.id" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 所属灌域：</div>
            <div class="content">
              <el-select v-model="Form.irrigationDistrictList" placeholder="请选择" multiple collapse-tags>
                <el-option
                  v-for="item in IrrigationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 设计流量：</div>
            <div class="content">
              <el-input
                v-model="Form.designFlow"
                clearable
                maxlength="32"
                placeholder="单位m³/s"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">m³/s</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 运行状况：</div>
            <div class="content">
              <el-select v-model="Form.runningState.id" placeholder="请选择">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="label">渠系水利用系数：</div>
            <div class="content">
              <el-input
                v-model="Form.coefficient"
                clearable
                maxlength="32"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">渠道长度：</div>
            <div class="content">
              <el-input
                v-model="Form.length"
                clearable
                maxlength="32"
                placeholder="单位m"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">m</div>
            </div>
          </div>
          <div class="search-item">
            <div class="label">灌溉面积：</div>
            <div class="content">
              <el-input
                v-model="Form.irrigationArea"
                clearable
                maxlength="32"
                placeholder="单位㎡"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">㎡</div>
            </div>
          </div>
        </div>

        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/channel/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import IrrigationSelect from "@/components/IrrigationSelect";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    IrrigationSelect,
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  data() {
    return {
      arr: [
        {
          text: "渠道管理",
          path: "/basic/channel/page",
        },
        {
          text: "添加渠道",
          path: null,
        },
      ],
      Form: {
        name: null,
        irrigationDistrictList: [],
        level: {
          id: null,
        },
        designFlow: null,
        coefficient: null,
        length: null,
        irrigationArea: null,
        runningState: {
          id: null,
        },
      },
      options: [],
      options2: [],
      IrrigationOptions: []
    };
  },
  methods: {
    ...mapActions(["AddChannel", "getDictionaryForType", "IrrigationPage"]),
    // 提交表单
    saveForm() {
      if (this.Form.name === null || this.Form.name === "") {
        this.$message.warning("渠道名称不能为空！");
        return;
      }
      if (
        this.Form.irrigationDistrictList === null ||
        this.Form.irrigationDistrictList.length === 0
      ) {
        this.$message.warning("所属灌域不能为空！");
        return;
      }
      if (this.Form.level.id === null || this.Form.level.id === "") {
        this.$message.warning("渠道级别不能为空！");
        return;
      }
      if (this.Form.designFlow === null || this.Form.designFlow === "") {
        this.$message.warning("设计流量不能为空！");
        return;
      }
      if (
        this.Form.runningState.id === null ||
        this.Form.runningState.id === ""
      ) {
        this.$message.warning("运行状况不能为空！");
        return;
      }
      let arr = this.Form.irrigationDistrictList.map(item => {
        return {
          id: item
        }
      })
      this.AddChannel({
        _this: this,
        data: {
          ...this.Form,
          irrigationDistrictList: arr
        }
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("新增成功！");
            this.$router.push("/basic/channel/page");
          }
        })
        .catch();
    },
  },
  mounted() {
    // 获取所有灌域
    this.IrrigationPage({
        _this: this,
        data: {
          pageIndex: 1,
          pageSize: 999,
        },
      })
        .then((res) => {
          if (res !== false) {
            res.body.records.forEach((item) => {
              this.IrrigationOptions.push({
                value: item.id,
                label: item.name,
              });
            });
          }
        })
        .catch();
    // 获取渠道级别下拉选项
    // 是否已有缓存
    this.options = this.allOptions.filter((item) => item.type === "CanalLevel");
    if (this.options.length > 0) {
      this.options = this.options[0].arr;
    } else {
      // 没有就去查
      this.getDictionaryForType({
        _this: this,
        type: "CanalLevel",
      })
        .then((res) => {
          if (res !== false) {
            res.body.options.forEach((item) => {
              this.options.push({
                value: item.id + "",
                label: item.name,
              });
            });
          }
        })
        .catch();
    }

    // 获取渠道运行状况下拉选项
    // 是否已有缓存
    this.options2 = this.allOptions.filter(
      (item) => item.type === "RunningState"
    );
    if (this.options2.length > 0) {
      this.options2 = this.options2[0].arr;
    } else {
      // 没有就去查
      this.getDictionaryForType({
        _this: this,
        type: "RunningState",
      })
        .then((res) => {
          if (res !== false) {
            res.body.options.forEach((item) => {
              this.options2.push({
                value: item.id + "",
                label: item.name,
              });
            });
          }
        })
        .catch();
    }
  },
};
</script>