<template>
  <div class="page detail">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-dharmachakra" text="灌域详情" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">灌域编码：</div>
            <div class="content">
              {{Form.code}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">灌域名称：</div>
            <div class="content">
              {{Form.name}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">灌域类别：</div>
            <div class="content">
              {{Form.type.name}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">管理机构：</div>
            <div class="content">
              {{Form.organization.name}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">设计灌溉面积：</div>
            <div class="content">
              {{Form.designIrrigationArea}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">渠首取水位置：</div>
            <div class="content">
              {{Form.canalHeaderIntakeLocation}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">渠首设计流量：</div>
            <div class="content">
              {{Form.canalHeaderDesignFlow}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">总干渠长度：</div>
            <div class="content">
              {{Form.canalLength}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">灌溉范围：</div>
            <div class="content">
              {{Form.irrigationScope}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">灌域围栏：</div>
            <div class="content">
              <div class="amap" id="amap"></div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 380px;">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              {{Form.remark}}
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/Irrigation/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import cover from "@/assets/cover.png";
import { mapActions } from 'vuex';
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "灌域管理",
          path: "/basic/Irrigation/page",
        },
        {
          text: "灌域详情",
          path: null,
        },
      ],
      Form: {
        id: null,
        name: null,
        code: null,
        type: {
          id: 1,
          name: null
        },
        organization: {
          id: 1,
          name: null
        },
        designIrrigationArea: null,
        canalHeaderIntakeLocation: null,
        canalHeaderDesignFlow: null,
        canalLength: null,
        irrigationScope: null,
        fences: [],
        remark: null
      },
      map: null
    };
  },
  methods: {
    ...mapActions(["IrrigationDetail"]),
      // 创建地图
    createMap() {
      let _this = this;
      // 构造地图对象的方法一：使用地图容器ID创建
      this.map = new AMap.Map("amap", {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 16, //设置地图显示的缩放级别
        //center: _this.Form.lnglat, //设置地图中心点坐标
        mapStyle: "amap://styles/normal", //设置地图的显示样式
        viewMode: "2D", //设置地图模式
        //lang: "zh_cn", //设置地图语言类型
      });
      this.map.on("complete", function () {
        // 地图图块加载完成后触发
        _this.drawPolygon();
        if (_this.Form.centerLocations.length > 0) {
          _this.setMarker(_this.Form.centerLocations[0])
        }
      });
    },
    // 绘制多边形
    drawPolygon() {
      var _this = this;
      var polygon = new AMap.Polygon({
        path: _this.Form.fences,
      });
      this.map.add(polygon);
      this.map.setFitView();
      this.polyEditor = new AMap.PolygonEditor(this.map);
      this.polyEditor.addAdsorbPolygons([polygon]);
      this.polyEditor.on("add", function (data) {
        var polygon = data.target;
        _this.polyEditor.addAdsorbPolygons(polygon);
      });
    },
    // 绘制Marker
    setMarker(lnglat) {
      // 获取已有覆盖物并清除
      let arr = this.map.getAllOverlays("marker");
      if (arr.length > 0) {
        this.map.remove(arr);
      }
      var marker = new AMap.Marker({
        icon: cover, //图片ip
        imageSize: "20px",
        position: lnglat,
        offset: new AMap.Pixel(-20, -20),
        // 设置是否可以拖拽
        draggable: false,
        cursor: "pointer",
        // 设置拖拽效果
        raiseOnDrag: true,
      });
      marker.setMap(this.map);
    },
    queryData(id) {
      this.IrrigationDetail({
        _this: this,
        id,
        data: this.Form
      }).then(res => {
        if (res !== false) {
          this.Form = res.body;
          this.createMap();
        }
      }).catch()
    }
  },
  mounted() {
      this.$nextTick(() => {
        let arr = this.$route.path.split("/");
        this.queryData(arr[arr.length - 1]);
      })
  }
};
</script>
<style lang="less" scoped>
.amap {
    height: 400px;
    width: 100%;
    margin-top: 10px;
}
</style>