<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-cloud-rain" text="水情统计" />
    <div class="page-main">
      <div class="btn-div">
        <Button icon="fa fa-file-signature" class="btn" text="导出" @click="exportFile()" />
      </div>
      <div class="table-wrapper">
        <el-table :data="tableData" border style="width: 100%" height="100%">
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column prop="stationName" label="站点名称"> </el-table-column>
          <el-table-column prop="averageLevel" label="平均水位(m)" width="120">
          </el-table-column>
          <el-table-column prop="highestLevel" label="最高水位(m)" width="120">
          </el-table-column>
          <el-table-column
            prop="lowestLevel"
            label="最低水位(m)"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="highestSpeed"
            label="最高水位流速(m³/s)"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="highestFlow"
            label="最高水位流量(m³)"
            width="160"
          ></el-table-column>
          <el-table-column label="最高水位测量时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.highestTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="lowestSpeed"
            label="最低水位流速(m³/s)"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="lowestFlow"
            label="最低水位流量(m³)"
            width="160"
          ></el-table-column>
          <el-table-column label="最低水位测量时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.lowestTime | dateformat }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from 'vuex';
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "统计分析",
          path: null,
        },
        {
          text: "水情统计",
          path: null,
        },
      ],
      Query: {
        pageIndex: 1,
        pageSize: 10,
        startDate: null,
        endDate: null,
        timeArr: [],
        stationNameLike: "",
        stationType: {
          id: 1
        }
      },
      tableData: [],
    };
  },
  methods: {
    ...mapActions(["StatisticsWaterPage", "exportExcel"]),
    queryData() {
      this.StatisticsWaterPage(this)
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body;
          }
        })
        .catch();
    },
    exportFile() {
      this.exportExcel({
        _this: this,
        filename: "水情统计表.xlsx"
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 312px);
}
</style>