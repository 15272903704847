<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-list-ol" text="待办事项" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">事件类型：</div>
          <div class="content">
            <el-select v-model="Query.businessType" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="search-item">
          <div class="label">申请人：</div>
          <div class="content">
            <el-input v-model="Query.userNameLike" clearable maxlength="32">
            </el-input>
          </div>
        </div>
        <div class="search-item">
          <!-- <div class="label">审批状态：</div>
          <div class="content">
            <el-select v-model="Query.state" placeholder="请选择">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-search"
          class="btn fr clearfix"
          text="查询"
          @click="queryData()"
        />
      </div>
      <div class="table-wrapper">
        <el-table :data="tableData" border style="width: 100%" height="100%">
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column label="事项类型">
            <template slot-scope="scope">
              <span
                class="link"
                @click="
                  $router.push(
                    scope.row.businessType === 1
                      ? '/water/water-user/detail/' +
                          scope.row.id +
                          '?uid=' +
                          scope.row.businessUid
                      : '/water/water-apply/detail/' +
                          scope.row.id +
                          '?uid=' +
                          scope.row.businessUid
                  )
                "
                >{{
                  scope.row.businessType === 1 ? "用水户审批" : "用水申请"
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="createUser.name"
            label="申请人"
            width="140"
          ></el-table-column>
          <!-- <el-table-column
            prop="approver.name"
            label="审批人"
            width="140"
          ></el-table-column> -->
          <el-table-column label="审批状态" width="180">
            <template slot-scope="scope">
              {{ scope.row.state | toggleState }}
            </template>
          </el-table-column>
          <el-table-column label="更新时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.modifyTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <span
                class="link"
                v-if="scope"
                @click="
                  $router.push(
                    scope.row.businessType === 1
                      ? '/water/water-user/approval/' +
                          scope.row.id +
                          '?uid=' +
                          scope.row.businessUid
                      : '/water/water-apply/approval/' +
                          scope.row.id +
                          '?uid=' +
                          scope.row.businessUid
                  )
                "
              >
                <i class="fa fa-file-signature"></i>
                审批
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="(val) => (Query.pageSize = val)"
          @current-change="(val) => (Query.pageIndex = val)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  watch: {
    "Query.pageIndex"() {
      this.queryData();
    },
    "Query.pageSize"() {
      this.queryData();
    },
  },
  data() {
    return {
      arr: [
        {
          text: "待办事项",
          path: null,
        },
      ],
      Query: {
        businessType: null,
        userNameLike: null,
        state: null,
        pageIndex: 1,
        pageSize: 10,
      },
      options2: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "新申请",
        },
        {
          value: 9,
          label: "同意",
        },
        {
          value: 8,
          label: "不同意",
        },
      ],
      options: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "用水户审批",
        },
        {
          value: 2,
          label: "用水申请",
        },
      ],
      tableData: [],
      total: 0,
    };
  },
  methods: {
    ...mapActions(["todoList"]),
    queryData() {
      this.todoList({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  margin-top: 35px;
  height: calc(100vh - 420px);
}
</style>