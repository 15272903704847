<template>
  <div class="page monitor">
    <BreadCrumb :arr="arr" />
    <div class="body">
      <div class="header">
        <div class="label">测站类型：</div>
        <div class="content">
          <el-radio v-model="Query.stationType" :label="null">全部</el-radio>
          <el-radio v-model="Query.stationType" :label="1">一体化闸门</el-radio>
          <el-radio v-model="Query.stationType" :label="2">渠道水位站</el-radio>
          <Button icon="fa fa-search" class="btn" text="查询" @click="getList()" />
        </div>
      </div>
      <div class="main">
        <div class="card" v-for="(item, index) in tableData" :key="index">
          <div class="card-header red">
            <i
              :class="item.stationType === 1 ? 'fa fa-pallet' : 'fa fa-broadcast-tower'"
              style="margin-right: 10px; margin-left: 5px"
            ></i>
            {{ item.stationName }}
            <span class="card-btn" @click="$router.push('/monitor/' + item.stationType + '-' + item.stationId)">
              详情
              <i class="fa fa-angle-double-right"></i>
            </span>
          </div>
          <img v-if="index % 2 == 0" src="@/assets/p1.png" alt="" class="card-left" />
          <img v-else src="@/assets/p2.png" alt="" class="card-left" />
          <div class="card-right">
            <div class="row-item">
              <div class="label">时间：</div>
              <div class="content">
                {{ item.recordModifyTime | dateformat() }}
              </div>
            </div>
            <div class="row-item">
              <div class="label">闸前水位：</div>
              <div class="content">
                {{ item.recordBeforeLevel }}m
              </div>
            </div>
            <div class="row-item">
              <div class="label">闸后水位：</div>
              <div class="content">
                {{ item.recordAfterLevel }}m
              </div>
            </div>
            <div class="row-item">
              <div class="label">瞬时流量：</div>
              <div class="content">
                {{ item.recordInstantFlow }}m³/s
              </div>
            </div>
            <div class="row-item">
              <div class="label">闸门开度：</div>
              <div class="content">
                {{ item.recordOpenSize }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-bottom: 20px">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="(val) => (Query.pageSize = val)"
          @current-change="(val) => (Query.pageIndex = val)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Button,
  },
  watch: {
    "Query.pageIndex"() {
      this.getList();
    },
    "Query.pageSize"() {
      this.getList();
    },
  },
  data() {
    return {
      arr: [
        {
          text: "实时监测",
          path: null,
        },
      ],
      Query: {
        stationType: null,
        pageIndex: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0
    };
  },
  methods: {
    ...mapActions(["monitorList"]),
    // 获取监控列表
    getList() {
      this.monitorList({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res != false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getList();
    });
  },
};
</script>
<style lang="less" scoped>
.body {
  padding: 0 20px;
  .header {
    margin-bottom: 10px;
    height: 80px;
    line-height: 80px;
    border-bottom: 1px solid #ddd;
    div {
      display: inline-block;
    }
    .label {
      color: #333;
      font-weight: 600;
      width: 80px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .main {
    .card {
      width: calc(50% - 10px);
      height: 245px;
      border: 1px solid #ddd;
      box-shadow: 0 0 5px #ddd;
      padding: 10px 15px;
      display: inline-block;
      margin-bottom: 20px;
      &:nth-of-type(2n) {
        margin-left: 20px;
      }
      .card-header {
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #ddd;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
        .card-btn {
          color: #409eff;
          font-size: 14px;
          float: right;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .card-left {
        height: calc(100% - 60px);
        width: 220px;
        vertical-align: top;
      }
      .card-right {
        vertical-align: top;
        display: inline-block;
        width: calc(100% - 230px);
        margin-left: 10px;
        height: calc(100% - 60px);
        .row-item {
          height: 30px;
          line-height: 30px;
          div {
            display: inline-block;
          }
          .label {
            color: #333;
            font-weight: 600;
            width: 80px;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>