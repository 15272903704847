<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-retweet" text="水权交易" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">买方机构：</div>
          <div class="content">
            <Organization @change="val => Query.buyerId = val" :typeId="6" @select="handleSelect1" />
          </div>
        </div>
        <div class="search-item">
          <div class="label">卖方机构：</div>
          <div class="content">
            <Organization @change="val => Query.sellerId = val" :typeId="6" @select="handleSelect2" />
          </div>
        </div>
        <div class="search-item">
          
        </div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-file-signature"
          class="btn"
          @click="$router.push('/water/transaction/add')"
          text="添加"
        />
        <Button
          icon="fa fa-search"
          class="btn fr clearfix"
          text="查询"
          @click="queryData()"
        />
      </div>
      <div class="table-wrapper">
        <el-table :data="tableData" border style="width: 100%" height="100%">
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column label="买方机构" width="180">
            <template slot-scope="scope">
              <span
                class="link"
                @click="$router.push('/water/transaction/detail/' + scope.row.id)"
                >{{ scope.row.buyer.name }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="seller.name"
            label="卖方机构"
            width="180"
          ></el-table-column>
          <el-table-column label="交易时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.transactionTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="waterQuantity"
            label="交易水量(m³)"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="单价(元)"
          ></el-table-column>
          <el-table-column
            prop="operator"
            label="经办人"
            width="120"
          ></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <span
                class="link"
                v-if="scope"
                @click="$router.push('/water/transaction/edit/' + scope.row.id)"
              >
                <i class="fa fa-edit"></i>
                编辑
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="(val) => (Query.pageSize = val)"
          @current-change="(val) => (Query.pageIndex = val)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import Organization from "@/components/Organization";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    Organization
  },
  watch: {
    "Query.pageIndex"() {
      this.queryData();
    },
    "Query.pageSize"() {
      this.queryData();
    }
  },
  data() {
    return {
      arr: [
        {
          text: "用水管理",
          path: null,
        },
        {
          text: "水权交易",
          path: null,
        },
      ],
      Query: {
        buyerId: null,
        sellerId: null,
        pageIndex: 1,
        pageSize: 10,
      },
      options: [],
      tableData: [],
      total: 0,
    };
  },
  methods: {
    ...mapActions(["TransactionPage"]),
    handleSelect1(val) {
      this.Query.buyerId = val
    },
    handleSelect2(val) {
      this.Query.sellerId = val
    },
    queryData() {
      this.TransactionPage({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 420px);
}
</style>