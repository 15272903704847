<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-file-signature" text="用水申请列表" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">申请人：</div>
          <div class="content">
            <User @change="val => Query.createUserId = val" @select="handleSelect" />
          </div>
        </div>
        <div class="search-item">
          <div class="label">申请时间：</div>
          <div class="content">
            <el-date-picker
              v-model="timeArr"
              type="datetimerange"
              range-separator="至"
              style="transform: translateY(5px)"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="search-item">
          <div class="label">审批状态：</div>
          <div class="content">
            <el-select v-model="Query.approvalState" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-file-signature"
          class="btn"
          @click="$router.push('/water/water-apply/apply')"
          text="申请"
        />
        <Button
          icon="fa fa-search"
          class="btn fr clearfix"
          text="查询"
          @click="queryData()"
        />
      </div>
      <div class="table-wrapper">
        <el-table :data="tableData" border style="width: 100%" height="100%">
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column label="申请人" width="120">
            <template slot-scope="scope">
              <span
                class="link"
                @click="$router.push('/water/water-apply/detail/' + scope.row.id)"
                >{{ scope.row.createUser.name }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="申请开始时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.startTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column label="申请结束时间" width="180">
            <template slot-scope="scope">
              {{ scope.row.endTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column
            prop="waterQuantity"
            label="申请用水量(m³)"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="device.name"
            label="放水闸门"
          ></el-table-column>
          <el-table-column
            prop="approvalUser.name"
            label="审批人"
            width="120"
          ></el-table-column>
          <el-table-column label="审批状态" width="100">
            <template slot-scope="scope">
              {{ scope.row.approvalState | toggleState }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" v-if="roleList.indexOf('ADMINISTRATOR') > -1 || roleList.indexOf('APPROVE_USER') > -1">
            <template slot-scope="scope">
              <span
                class="link"
                v-if="scope.row.approvalState === 1"
                @click="$router.push('/water/water-apply/approval/' + scope.row.id)"
              >
                <i class="fa fa-file-signature"></i>
                审批
              </span>
              <span
                class="link"
                v-if="scope.row.approvalState === 9"
                @click="toHome()"
              >
                <i class="fa fa-retweet"></i>
                调度
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="(val) => (Query.pageSize = val)"
          @current-change="(val) => (Query.pageIndex = val)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import User from "@/components/User";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    User
  },
  computed: {
    ...mapState(["myInfo"])
  },
  watch: {
    "Query.pageIndex"() {
      this.queryData();
    },
    "Query.pageSize"() {
      this.queryData();
    },
    timeArr: {
      handler(val) {
        if (val != null && val.length > 0) {
          this.Query.startStartTime = this.$utils.fixDateTime(val[0]);
          this.Query.endStartTime = this.$utils.fixDateTime(val[1]);
        } else {
          this.Query.startStartTime = null;
          this.Query.endStartTime = null;
        }
      },
    },
  },
  data() {
    return {
      arr: [
        {
          text: "用水管理",
          path: null,
        },
        {
          text: "用水申请列表",
          path: null,
        },
      ],
      Query: {
        createUserId: null,
        approvalState: null,
        startStartTime: null,
        endStartTime: null,
        pageIndex: 1,
        pageSize: 10,
      },
      timeArr: [],
      options: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "新申请",
        },
        {
          value: 9,
          label: "同意",
        },
        {
          value: 8,
          label: "不同意",
        },
      ],
      tableData: [],
      total: 0,
      roleList: []
    };
  },
  methods: {
    ...mapActions(["WaterApplyPage"]),
    handleSelect(val) {
      this.Query.createUserId = val
    },
    toHome() {
      this.$router.push({
        path: '/home',
        name: 'Home',
        query: {
          id: 1
        }
      })
    },
    queryData() {
      this.WaterApplyPage({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
            if(this.total < (this.Query.pageIndex - 1) * this.Query.pageSize + 1) {
              this.Query.pageIndex = 1
            }
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.roleList = this.myInfo.roleList.map(item => item.name)
      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 420px);
}
</style>