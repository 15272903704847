<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-pallet" text="闸站管理" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">闸站名称：</div>
          <div class="content">
            <el-input v-model="Query.nameLike" clearable maxlength="50"></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="label">所属灌域：</div>
          <div class="content">
            <IrrigationSelect @change="val => Query.irrigationDistrictId = val" @select="handleSelect" />
          </div>
        </div>
        <div class="search-item">
          <div class="label">管理机构：</div>
          <div class="content">
            <Organization @change="val => Query.organizationId = val" @select="handleSelect2" />
          </div>
        </div>
      </div>
      <div class="btn-div">
        <Button icon="fa fa-plus" class="btn" text="添加" @click="$router.push('/basic/gate/add')" />
        <Button
          icon="fa fa-trash-alt"
          class="btn"
          text="删除"
          style="margin-left: 10px"
          @click="del()"
        />
        <Button icon="fa fa-search" class="btn fr clearfix" text="查询" @click="queryData()" />
      </div>
      <div class="table-wrapper">
        <el-table :data="tableData" border style="width: 100%" height="100%" @selection-change="handleSelectionChange">
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="闸站名称">
            <template slot-scope="scope">
              <span class="link" @click="$router.push('/basic/gate/detail/' + scope.row.gateId)">{{scope.row.stationName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="stationIrrigationDistrictName" label="所属灌域" width="160"></el-table-column>
          <el-table-column prop="stationOrganizationName" label="管理机构" width="160"></el-table-column>
          <el-table-column prop="gateType.name" label="闸站类型" width="120"></el-table-column>
          <el-table-column prop="stationAddress" label="闸站位置" width="220"></el-table-column>
          <el-table-column prop="gateBoardQuantity" label="闸板数量(个)" width="120"></el-table-column>
          <el-table-column prop="gateHeight" label="净高(m)" width="120"></el-table-column>
          <el-table-column prop="gateWidth" label="净宽(m)" width="80"></el-table-column>
          <el-table-column prop="x3" label="操作" width="100">
                <template slot-scope="scope">
                  <span class="link" v-if="scope" @click="$router.push('/basic/gate/edit/' + scope.row.gateId)">
                    <i class="fa fa-edit"></i>
                    编辑
                  </span>
                </template>
              </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="val => Query.pageSize = val"
          @current-change="val => Query.pageIndex = val"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import IrrigationSelect from "@/components/IrrigationSelect";
import Organization from "@/components/Organization";
import { mapActions } from 'vuex';
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    IrrigationSelect,
    Organization
  },
  watch: {
    'Query.pageIndex'() {
      this.queryData();
    },
    'Query.pageSize'() {
      this.queryData();
    }
  },
  data() {
    return {
      arr: [
        {
          text: "基础信息",
          path: null,
        },
        {
          text: "闸站管理",
          path: null,
        },
      ],
      Query: {
        pageIndex: 1,
        pageSize: 10,
        nameLike: "",
        irrigationDistrictId: null,
        organizationId: null,
        type: 1
      },
      options: [
        {
          value: null,
          label: "全部",
        },
      ],
      tableData: [],
      total: 0,
      multipleSelection: [],
    };
  },
  methods: {
    ...mapActions(["StationPage", "DelGate"]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSelect(val) {
      this.Query.irrigationDistrictId = val
    },
    handleSelect2(val) {
      this.Query.organizationId = val
    },
    queryData() {
      this.StationPage({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
    del() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning("请选中您要删除的行！");
        return;
      }
      let arr = this.multipleSelection.map((item) => item.gateId);
      this.DelGate({
        _this: this,
        data: arr,
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("删除成功！");
            this.queryData();
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 412px);
}
</style>