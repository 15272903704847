<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-file-signature" text="用水申请审批" />
    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        申请信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">申请人：</div>
            <div class="content" v-if="Form.id != null">{{ Form.createUser.name }}</div>
          </div>
          <div class="search-item">
            <div class="label">申请时间：</div>
            <div class="content" v-if="Form.id != null">
              {{ Form.createTime | dateformat() }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">申请开始时间：</div>
            <div class="content" v-if="Form.id != null">
              {{ Form.startTime | dateformat() }}
            </div>
          </div>
          <div class="search-item">
            <div class="label">申请结束时间：</div>
            <div class="content" v-if="Form.id != null">
              {{ Form.endTime | dateformat() }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">申请用水量：</div>
            <div
              class="content"
              v-if="Form.id != null"
              style="font-weight: bold; color: #000"
            >
              {{Form.waterQuantity}}m³
            </div>
          </div>
          <div class="search-item"></div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content" v-if="Form.id != null">{{ Form.remark }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        审批信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 放水闸门：</div>
            <div class="content" v-if="Form.id != null">
              <el-select v-model="Form.device.id" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 是否同意：</div>
            <div class="content">
              <el-radio v-model="Form.approvalState" :label="9">同意</el-radio>
              <el-radio v-model="Form.approvalState" :label="8"
                >不同意</el-radio
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 审批意见：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.approvalComments"
                clearable
                maxlength="300"
                type="textarea"
                style="margin-top: 15px;"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="btn-list" style="transform: translateY(15px)">
          <Button icon="fa fa-retweet" class="btn" text="确定" @click="saveForm()" />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="
              $router.push(
                $route.query.uid ? '/todo' : '/water/water-apply/page'
              )
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "用水管理",
          path: "/water/water-apply/page",
        },
        {
          text: "用水申请审批",
          path: null,
        },
      ],
      Form: {
        id: null,
        device: {
          id: null
        },
        approvalState: null,
        approvalComments: null
      },
      options: [],
    };
  },
  methods: {
    ...mapActions(["WaterApplyDetail", "AllDevice" , "ApprovalWaterApply", "WaterApplyDetailByUid"]),
    saveForm() {
      if (this.Form.device.id === null || this.Form.device.id === "") {
        this.$message.warning("放水闸门不能为空！");
        return;
      }
      if (this.Form.approvalState === null || this.Form.approvalState === "" || this.Form.approvalState === 1) {
        this.$message.warning("是否同意不能为空！");
        return;
      }
      if (this.Form.approvalComments === null || this.Form.approvalComments === "") {
        this.$message.warning("审批意见不能为空！");
        return;
      }
      this.ApprovalWaterApply({
        _this: this,
        data: this.Form,
        id: this.Form.id
      }).then(res => {
        if (res != false) {
          this.$message.success("审批成功！")
          this.$router.push("/water/water-apply/page")
        }
      }).cacth()
    }
  },
  mounted() {
    this.$nextTick(() => {
      let id = this.$route.params.id;
      let uid = this.$route.query.uid;
      if (uid) {
        this.WaterApplyDetailByUid({
          _this: this,
          uid,
        })
          .then((res) => {
            if (res != false) {
              this.Form = {
                ...res.body,
                device: res.body.device === null ? this.Form.device : res.body.device
              };
            }
          })
          .catch();
      } else {
        this.WaterApplyDetail({
          _this: this,
          id,
        })
          .then((res) => {
            if (res != false) {
              this.Form = {
                ...res.body,
                device: res.body.device === null ? this.Form.device : res.body.device
              };
            }
          })
          .catch();
      }
      this.AllDevice(this).then(res => {
        if (res != false) {
          res.body.forEach(item => {
            this.options.push({
              value: item.id,
              label: item.name
            })
          })
        }
      }).catch()
    });
  },
};
</script>
<style lang="less" scoped>
.content {
  color: #777;
}
.apply-main {
  padding: 20px 0;
}
.search-item {
  margin-bottom: 0 !important;
}
.row {
  margin-top: 0px;
}
</style>