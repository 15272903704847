<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-cloud-rain" text="灌域管理" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">灌域名称：</div>
          <div class="content">
            <el-input
              v-model="Query.nameLike"
              clearable
              maxlength="50"
            ></el-input>
          </div>
        </div>
        <div class="search-item">
          <div class="label">管理机构：</div>
          <div class="content">
            <Organization @change="val => Query.organizationId = val" @select="handleSelect" />
          </div>
        </div>
        <div class="search-item">
          <div class="label">灌域类型：</div>
          <div class="content">
            <el-select v-model="Query.typeId" clearable placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-plus"
          class="btn"
          text="添加"
          @click="$router.push('/basic/Irrigation/add')"
        />
        <Button
          icon="fa fa-trash-alt"
          class="btn"
          text="删除"
          style="margin-left: 10px"
          @click="del()"
        />
        <Button
          icon="fa fa-search"
          class="btn fr clearfix"
          text="查询"
          @click="queryData()"
        />
      </div>
      <div class="table-wrapper">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="灌域名称">
            <template slot-scope="scope">
              <span
                class="link"
                @click="
                  $router.push('/basic/Irrigation/detail/' + scope.row.id)
                "
                >{{ scope.row.name }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="type.name"
            label="灌域类型"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="organization.name"
            label="管理机构"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="designIrrigationArea"
            label="设计灌溉面积(㎡)"
            width="160"
          ></el-table-column>
          <el-table-column
            prop="canalHeaderIntakeLocation"
            label="渠首取水位置"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="canalHeaderDesignFlow"
            label="渠首设计流量(m³/s)"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="canalLength"
            label="总干渠长度(m)"
            width="120"
          ></el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <span
                class="link"
                v-if="scope"
                @click="$router.push('/basic/Irrigation/edit/' + scope.row.id)"
              >
                <i class="fa fa-edit"></i>
                编辑
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="val => Query.pageSize = val"
          @current-change="val => Query.pageIndex = val"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import Organization from "@/components/Organization";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    Organization
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  watch: {
    'Query.pageIndex'() {
      this.queryData();
    },
    'Query.pageSize'() {
      this.queryData();
    }
  },
  data() {
    return {
      arr: [
        {
          text: "基础信息",
          path: null,
        },
        {
          text: "灌域管理",
          path: null,
        },
      ],
      Query: {
        nameLike: "",
        organizationId: null,
        typeId: null,
        pageIndex: 1,
        pageSize: 10,
      },
      options: [],
      options2: [
        {
          value: 1,
          label: "系统内置"
        }
      ],
      tableData: [],
      multipleSelection: [],
      total: 0,
    };
  },
  methods: {
    ...mapActions(["IrrigationPage", "DelIrrigation", "getDictionaryForType"]),
    handleSelect(val) {
      this.Query.organizationId = val
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    queryData() {
      this.IrrigationPage({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
    del() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning("请选中您要删除的行！");
        return;
      }
      let arr = this.multipleSelection.map((item) => item.id);
      this.DelIrrigation({
        _this: this,
        data: arr,
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("删除成功！");
            this.queryData();
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 获取灌域类别下拉选项
      // 是否已有缓存
      this.options = this.allOptions.filter(
        (item) => item.type === "IrrigationDistrictType"
      );
      if (this.options.length > 0) {
        this.options = this.options[0].arr;
      } else {
        // 没有就去查
        this.getDictionaryForType({
          _this: this,
          type: "IrrigationDistrictType",
        })
          .then((res) => {
            if (res !== false) {
              res.body.options.forEach((item) => {
                this.options.push({
                  value: item.id + "",
                  label: item.name,
                });
              });
            }
          })
          .catch();
      }

      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 412px);
}
</style>