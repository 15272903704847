<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-book-open" text="数据字典" />
    <div class="page-main">
      <div class="search-div">
        <div class="search-item">
          <div class="label">名称：</div>
          <div class="content">
            <el-input v-model="Query.nameLike" clearable maxlength="32">
              </el-input>
          </div>
        </div>
        <div class="search-item"></div>
        <div class="search-item"></div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-search"
          class="btn fr clearfix"
          text="查询"
          @click="queryData()"
        />
      </div>
      <div class="table-wrapper">
        <el-table :data="tableData" border style="width: 100%; margin-top: 40px;" height="100%">
          <el-table-column label="序号" type="index" width="80">
          </el-table-column>
          <el-table-column
            prop="name"
            label="名称"
            width="200"
          ></el-table-column>
          <el-table-column
            label="字典值"
          >
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.options" :key="index">{{item.name}}  </span>
          </template>
          </el-table-column>
          <el-table-column
            label="备注"
            prop="remark"
          ></el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <span
                class="link"
                v-if="scope"
                @click="$router.push('/system/dictionary/edit/' + scope.row.type)"
              >
                <i class="fa fa-edit"></i>
                编辑
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="(val) => (Query.pageSize = val)"
          @current-change="(val) => (Query.pageIndex = val)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  data() {
    return {
      arr: [
        {
          text: "系统管理",
          path: null,
        },
        {
          text: "数据字典",
          path: null,
        },
      ],
      tableData: [],
      Query: {
        pageIndex: 1,
        pageSize: 10,
        nameLike: null,
      },
      total: 0
    };
  },
  methods: {
    ...mapActions(["getDictionaryPage"]),
    queryData() {
      this.getDictionaryPage({
        _this: this,
        data: this.Query
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
  },
  mounted() {
    this.queryData();
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 412px);
}
</style>