<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-user-shield" text="角色管理" />
    <div class="page-main">
        <div class="search-div">
        <div class="search-item">
          <div class="label">角色名：</div>
          <div class="content">
            <el-input v-model="Query.textLike" placeholder="" clearable>
            </el-input>
          </div>
        </div>
        <div class="search-item"></div>
        <div class="search-item"></div>
      </div>
      <div class="btn-div">
        <Button
          icon="fa fa-plus"
          class="btn"
          text="添加"
          @click="$router.push('/system/role/add')"
        />
        <Button
          icon="fa fa-search"
          class="btn fr clearfix"
          text="查询"
          @click="queryData()"
        />
      </div>
      <div class="table-wrapper">
        <el-table :data="tableData" border style="width: 100%" height="100%">
          <el-table-column label="序号" type="index" width="60">
          </el-table-column>
          <el-table-column
            prop="text"
            label="角色名"
            width="160"
          ></el-table-column>
          <el-table-column prop="usageQuantity" label="使用人数" width="120">
          </el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column prop="address" label="操作" width="160">
          <template slot-scope="scope">
            <span class="link operate-btn" @click="$router.push('/system/role/edit/' + scope.row.id)">
              <i class="fa fa-edit"></i>
              编辑
            </span>
          </template>
        </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center; margin: 5px 0">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="Query.pageIndex"
          :page-size="Query.pageSize"
          :total="total"
          @size-change="(val) => (Query.pageSize = val)"
          @current-change="(val) => (Query.pageIndex = val)"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  watch: {
    "Query.pageIndex"() {
      this.queryData();
    },
    "Query.pageSize"() {
      this.queryData();
    },
  },
  data() {
    return {
      arr: [
        {
          text: "系统管理",
          path: null,
        },
        {
          text: "角色管理",
          path: null,
        },
      ],
      Query: {
        pageIndex: 1,
        pageSize: 10,
        textLike: null,
      },
      tableData: [],
      total: 0,
    };
  },
  methods: {
    ...mapActions(["getRolePage"]),
    queryData() {
      this.getRolePage({
          _this: this,
          data: this.Query
      })
        .then((res) => {
          if (res !== false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData();
    });
  },
};
</script>
<style lang="less" scoped>
.table-wrapper {
  height: calc(100vh - 412px);
}
</style>