<template>
  <div class="page detail">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-user-plus" text="用水户申请详情" />
    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        申请信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">协会：</div>
            <div class="content" v-if="Form.id">
              {{ Form.organization.name }}
            </div>
          </div>
          <div class="search-item">
            <div class="label">灌域：</div>
            <div class="content" v-if="Form.id">
              {{ Form.irrigationDistrict.name }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">放水闸门：</div>
            <div class="content" v-if="Form.id && Form.device != null">
              <span v-for="(item, index) in Form.device" :key="index">
                {{ item.name }}
              </span>
            </div>
          </div>
          <div class="search-item">
            <div class="label">申请人：</div>
            <div class="content" v-if="Form.id">{{ Form.createUser.name }}</div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">手机号：</div>
            <div
              class="content"
              v-if="Form.id"
              style="font-weight: bold; color: #000"
            >
              {{ Form.mobile }}
            </div>
          </div>
          <div class="search-item"></div>
        </div>
      </div>
    </div>

    <div class="apply-body">
      <div class="apply-header">
        <i class="fa fa-info-circle" style="margin: 0 10px"></i>
        审批信息
      </div>
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">审批状态：</div>
            <div class="content" v-if="Form.id">
              {{ Form.approvalState | toggleState }}
            </div>
          </div>
          <div class="search-item">
            <div class="label">审批时间：</div>
            <div class="content" v-if="Form.id">
              {{ Form.approvalTime | dateformat() }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">审批人：</div>
            <div class="content" v-if="Form.id && Form.approvalUser != null">
              {{ Form.approvalUser.name }}
            </div>
          </div>
          <div class="search-item"></div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">审批意见：</div>
            <div class="content" v-if="Form.id">
              {{ Form.approvalComments }}
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push($route.query.uid ? '/todo' : '/water/water-user/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "用水管理",
          path: "/water/water-user/page",
        },
        {
          text: "用水户申请详情",
          path: null,
        },
      ],
      Form: {},
    };
  },
  methods: {
    ...mapActions(["WaterUserDetail", "WaterUserDetailByUid"]),
  },
  mounted() {
    this.$nextTick(() => {
      let id = this.$route.params.id;
      let uid = this.$route.query.uid;
      if (uid) {
        this.WaterUserDetailByUid({
          _this: this,
          uid,
        })
          .then((res) => {
            if (res != false) {
              this.Form = res.body;
            }
          })
          .catch();
      } else {
        this.WaterUserDetail({
          _this: this,
          id
        })
          .then((res) => {
            if (res != false) {
              this.Form = res.body;
            }
          })
          .catch();
      }
    });
  },
};
</script>