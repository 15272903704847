<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-pallet" text="添加闸站" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 闸站名称：</div>
            <div class="content">
              <el-input v-model="Form.station.name" clearable maxlength="32">
              </el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 所属灌域：</div>
            <div class="content">
              <IrrigationSelect
                @change="(val) => (Form.station.irrigationDistrict.id = val)"
                @select="handleSelect"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 管理机构：</div>
            <div class="content">
              <Organization
                @change="(val) => (Form.station.organization.id = val)"
                @select="handleSelect2"
              />
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 闸站类型：</div>
            <div class="content">
              <el-select v-model="Form.type.id" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 闸板数量：</div>
            <div class="content">
              <el-input
                v-model="Form.boardQuantity"
                clearable
                maxlength="32"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">个</div>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 站址：</div>
            <div class="content">
              <el-input v-model="Form.station.address" clearable maxlength="32">
              </el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">净宽：</div>
            <div class="content">
              <el-input
                v-model="Form.width"
                clearable
                maxlength="32"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">m</div>
            </div>
          </div>
          <div class="search-item">
            <div class="label">净高：</div>
            <div class="content">
              <el-input
                v-model="Form.height"
                clearable
                maxlength="32"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">m</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.station.remark"
                clearable
                maxlength="300"
                type="textarea"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/gate/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions, mapState } from "vuex";
import IrrigationSelect from "@/components/IrrigationSelect";
import Organization from "@/components/Organization";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    IrrigationSelect,
    Organization,
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  data() {
    return {
      arr: [
        {
          text: "基础信息",
          path: null,
        },
        {
          text: "闸站管理",
          path: "/basic/gate/page",
        },
        {
          text: "添加闸站",
          path: null,
        },
      ],
      Form: {
        station: {
          irrigationDistrict: {
            id: null,
          },
          organization: {
            id: null,
          },
          name: null,
          address: null,
          remark: null
        },
        type: {
          id: null,
        },
        boardQuantity: null,
        height: null,
        width: null,
      },
      options: [],
    };
  },
  methods: {
    ...mapActions(["AddGate", "getDictionaryForType"]),
    handleSelect(val) {
      this.Form.station.irrigationDistrict.id = val;
    },
    handleSelect2(val) {
      this.Form.station.organization.id = val;
    },
    // 提交表单
    saveForm() {
      if (this.Form.station.name === null || this.Form.station.name === "") {
        this.$message.warning("闸站名称不能为空！");
        return;
      }
      if (
        this.Form.station.irrigationDistrict.id === null ||
        this.Form.station.irrigationDistrict.id === ""
      ) {
        this.$message.warning("所属灌域不能为空！");
        return;
      }
      if (this.Form.station.irrigationDistrict.id === 999) {
        this.$message.warning(
          "不存在您输入的灌域名称，请通过下拉点击填入灌域！"
        );
        return;
      }
      if (
        this.Form.station.organization.id === null ||
        this.Form.station.organization.id === ""
      ) {
        this.$message.warning("管理机构不能为空！");
        return;
      }
      if (
        this.Form.station.organization.id === 999
      ) {
        this.$message.warning("不存在您输入的管理机构名称，请通过下拉点击填入管理机构！");
        return;
      }
      if (this.Form.type.id === null || this.Form.type.id === "") {
        this.$message.warning("闸站类型不能为空！");
        return;
      }
      if (this.Form.boardQuantity === null || this.Form.boardQuantity === "") {
        this.$message.warning("闸板数量不能为空！");
        return;
      }
      if (
        this.Form.station.address === null ||
        this.Form.station.address === ""
      ) {
        this.$message.warning("站址不能为空！");
        return;
      }

      this.AddGate({
        _this: this,
        data: this.Form,
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("新增成功！");
            this.$router.push("/basic/gate/page");
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      // 获取闸站类型下拉选项
      this.options = this.allOptions.filter((item) => item.type === "GateType");
      if (this.options.length > 0) {
        this.options = this.options[0].arr;
      } else {
        // 没有就去查
        this.getDictionaryForType({
          _this: this,
          type: "GateType",
        })
          .then((res) => {
            if (res !== false) {
              res.body.options.forEach((item) => {
                this.options.push({
                  value: item.id + "",
                  label: item.name,
                });
              });
            }
          })
          .catch();
      }
    });
  },
};
</script>