<template>
  <div class="home">
    <div class="amap" id="amap" :class="isClose == true ? 'big-amap' : ''">
      <iframe
        v-show="backindex"
        :src="publicPath + '/map/map2D/onemap.html'"
        style="width: 100%; height: 100%"
        frameborder="0"
        id="iframeBox"
        @load="mapLoaded"
      ></iframe>
      <div class="small-tip" v-show="!showTip" @click="showTip = true">
        <i class="fa fa-angle-double-down"></i>
      </div>
      <div class="tip" v-show="showTip">
        <i class="fa fa-info tip-icon"></i>
        1号闸门正在放水。流速33m3/s，开始时间2020-5-21。
        <i class="fa fa-times tip-close" @click="showTip = false"></i>
      </div>
    </div>
    <div class="drawer" :class="isClose == true ? 'is-close' : ''">
      <div class="drawer-main">
        <div class="drawer-btn" @click="isClose = !isClose">
          <i
            class="fa"
            :class="
              isClose == true ? 'fa-angle-double-left' : 'fa-angle-double-right'
            "
          ></i>
        </div>
        <div class="drawer-body">
          <div class="title">监控列表</div>
          <div class="row-item">
            <div class="label">测站名称：</div>
            <div class="content">
              <StationSelect
                @change="val => Query.stationId = val"
                @select="handleSelect"
              />
            </div>
          </div>
          <div class="row-item">
            <div class="label">测站类型：</div>
            <div class="content">
              <el-radio v-model="Query.stationType" :label="1"
                >一体化闸门</el-radio
              >
              <el-radio v-model="Query.stationType" :label="2"
                >渠道水位站</el-radio
              >
              <Button
                icon="fa fa-search"
                class="btn"
                text="查询"
                @click="getList()"
              />
            </div>
          </div>
          <div class="table-wrapper">
            <el-table
              :data="tableData"
              border
              style="width: 100%"
              height="100%"
            >

              <el-table-column prop="stationName" label="测站名称">
              <template slot-scope="scope">
                <span class="link" @click="stationFly(scope.$index, scope.row)">{{ scope.row.stationName }}</span> 
              </template>
              </el-table-column>
              <el-table-column prop="recordLevel" label="水位(m)" width="80">
              </el-table-column>
              <el-table-column
                prop="recordInstantFlow"
                label="瞬时流量(m³/s)"
                width="130"
              >
              </el-table-column>
              <el-table-column label="时间" width="160">
                <template slot-scope="scope">
                  {{ scope.row.recordModifyTime | dateformat }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="text-align: center; margin: 5px 0">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="Query.pageIndex"
              :page-size="Query.pageSize"
              :total="total"
              @size-change="(val) => (Query.pageSize = val)"
              @current-change="(val) => (Query.pageIndex = val)"
            >
            </el-pagination>
          </div>
          <div class="title">水量统计TOP10</div>
          <div class="row-item" style="border-bottom: 1px solid #ddd">
            <div class="label">统计类型：</div>
            <div>
              <el-radio v-model="Search.statisticsType.id" :label="1"
                >当月</el-radio
              >
              <el-radio v-model="Search.statisticsType.id" :label="2"
                >逐月</el-radio
              >
            </div>
          </div>
          <div class="bar-charts" ref="barCharts"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Button from "@/components/Button";
import StationSelect from "@/components/StationSelect";
import { mapActions } from "vuex";
import axios from 'axios'

export default {
  components: {
    Button,
    StationSelect,
  },
  watch: {
    "Query.pageIndex"() {
      this.getList();
    },
    "Query.pageSize"() {
      this.getList();
    },
    "Search.statisticsType.id"() {
      this.drawCharts();
    }
  },
  data() {
    return {
      map: null,
      marker: null,
      thisMapManager:null,
      isClose: false,
      Query: {
        stationId: null,
        stationType: 1,
        pageIndex: 1,
        pageSize: 10,
      },
      Search: {
        statisticsType: {
          id: 1,
        },
      },
      tableData: [],
      showTip: true,
      total: 0,
      backindex: true, //2.地图是否显示
      publicPath: process.env.BASE_URL, //3.public路径获取
      controlModeData:[]
    };
  },
  methods: {
    ...mapActions(["monitorList", "topTen"]),
    handleSelect(val) {
      this.Query.stationId = val;
    },
    // 创建地图
    createMap() {
      let _this = this;
      // 构造地图对象的方法一：使用地图容器ID创建
      this.map = new AMap.Map("amap", {
        resizeEnable: true, //是否监控地图容器尺寸变化
        zoom: 12, //设置地图显示的缩放级别
        //center: _this.Form.lnglat, //设置地图中心点坐标
        mapStyle: "amap://styles/normal", //设置地图的显示样式
        viewMode: "2D", //设置地图模式
        //lang: "zh_cn", //设置地图语言类型
      });
      this.map.on("complete", function () {
        // 地图图块加载完成后触发
        _this.drawMarker(
          "1号闸门",
          [113.45392786145018, 23.16999551949372],
          "red",
          1
        );
        _this.drawMarker(
          "1号水文站",
          [113.45292786145018, 23.16399551949372],
          "blue",
          2
        );
        _this.drawMarker(
          "2号水文站",
          [113.45292786145018, 23.14799551949372],
          "grey",
          2
        );
      });
    },
    drawCharts() {
      this.topTen({
        _this: this,
        data: this.Search,
      })
        .then((res) => {
          if (res != false) {
            let xaxis = res.body.xaxis;
            let series = res.body.series;
            series = series.map((item) => {
              return {
                type: "bar",
                showBackground: true,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#83bff6" },
                    { offset: 0.5, color: "#188df0" },
                    { offset: 1, color: "#188df0" },
                  ]),
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#2378f7" },
                      { offset: 0.7, color: "#2378f7" },
                      { offset: 1, color: "#83bff6" },
                    ]),
                  },
                },
                ...item,
              };
            });
            var echartsDom = echarts.init(this.$refs.barCharts);
            echartsDom.setOption({
              xAxis: {
                data: xaxis,
              },
              yAxis: {
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  textStyle: {
                    color: "#999",
                  },
                },
              },
              grid: {
                // 边距
                x: 10, // 左
                y: 10, // 上
                x2: 10, // 右
                y2: 10, // 下
                containLabel: true,
              },
              dataZoom: [
                {
                  type: "inside",
                },
              ],
              series,
            });
            var zoomSize = 6;
            echartsDom.on("click", function (params) {
              echartsDom.dispatchAction({
                type: "dataZoom",
                startValue:
                  dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
                endValue:
                  dataAxis[
                    Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
                  ],
              });
            });
          }
        })
        .catch();
    },
    // 自定义Marker内容(div)
    drawMarker(text, lnglat, color, type) {
      let div = document.createElement("div");
      let template = `
        <div class="marker-div ${color}" onclick="openWindow(${type})">${text}</div>
      `;
      div.innerHTML = template;

      this.marker = new AMap.Marker({
        position: lnglat,
        offset: new AMap.Pixel(-25, -25),
        // 设置是否可以拖拽
        draggable: false,
        cursor: "move",
        // 设置拖拽效果
        raiseOnDrag: true,
        content: div,
      });
      this.marker.setMap(this.map);
      if (this.map != null) {
        this.map.setFitView();
      }
    },
    mapLoaded(evt) {
      //5.地图ifr加载完成回调
      this.mapWindow = evt.target.contentWindow;
      evt.target.contentWindow.getMapManager(function (mapManager) {
        let  thisMapManager = mapManager;
        var list = [
          {
            x: 106.19,
            y: 37.98,
            stnm: "秦渠1号闸",
            sttp: "zm",
            status: "1",
            remark: "测站1",
          },
          {
            x: 106.2,
            y: 37.97,
            stnm: "秦渠2号闸",
            sttp: "zm",
            status: "0",
            remark: "测站2",
          },
          {
            x: 106.21,
            y: 37.96,
            stnm: "秦渠1号水文站",
            sttp: "cz",
            status: "",
            remark: "水文站",
          },
          {
            x: 106.25,
            y: 37.91,
            stnm: "汉渠1号闸",
            sttp: "zm",
            status: "1",
            remark: "测站4",
          },
          {
            x: 106.22,
            y: 37.9,
            stnm: "汉渠2号闸",
            sttp: "zm",
            status: "0",
            remark: "测站5",
          },
          {
            x: 106.18,
            y: 37.89,
            stnm: "汉渠3号闸",
            sttp: "zm",
            status: "",
            remark: "测站6",
          },
          {
            x: 106.15,
            y: 37.92,
            stnm: "马莲渠1号闸",
            sttp: "zm",
            status: "1",
            remark: "测站7",
          },
          {
            x: 106.17,
            y: 37.92,
            stnm: "马莲渠2号闸",
            sttp: "zm",
            status: "0",
            remark: "测站8",
          },
          {
            x: 106.18,
            y: 37.93,
            stnm: "马莲渠3号闸",
            sttp: "zm",
            status: "",
            remark: "测站9",
          },
          {
            x: 106.33,
            y: 37.78,
            stnm: "五里坡1号闸",
            sttp: "zm",
            status: "1",
            remark: "测站10",
          },
          {
            x: 106.32,
            y: 37.79,
            stnm: "五里坡2号闸",
            sttp: "zm",
            status: "0",
            remark: "测站11",
          },
          {
            x: 106.3,
            y: 37.77,
            stnm: "五里坡3号闸",
            sttp: "zm",
            status: "",
            remark: "测站12",
          },
          {
            x: 106.25,
            y: 37.83,
            stnm: "东干渠1号闸",
            sttp: "zm",
            status: "1",
            remark: "测站13",
          },
          {
            x: 106.17,
            y: 37.84,
            stnm: "东干渠2号闸",
            sttp: "zm",
            status: "0",
            remark: "测站14",
          },
          {
            x: 106.24,
            y: 37.81,
            stnm: "东干渠3号闸",
            sttp: "zm",
            status: "",
            remark: "测站15",
          },
          {
            x: 106.25,
            y: 37.75,
            stnm: "扁担沟1号闸",
            sttp: "zm",
            status: "1",
            remark: "测站16",
          },
          {
            x: 106.21,
            y: 37.8,
            stnm: "扁担沟2号闸",
            sttp: "zm",
            status: "0",
            remark: "测站17",
          },
          {
            x: 106.24,
            y: 37.71,
            stnm: "扁担沟3号闸",
            sttp: "zm",
            status: "",
            remark: "测站18",
          },
          {
            x: 106.13,
            y: 37.94,
            stnm: "波浪渠1号闸",
            sttp: "zm",
            status: "1",
            remark: "测站19",
          },
          {
            x: 106.17,
            y: 37.94,
            stnm: "波浪渠2号闸",
            sttp: "zm",
            status: "0",
            remark: "测站20",
          },
          {
            x: 106.16,
            y: 37.94,
            stnm: "波浪渠3号闸",
            sttp: "zm",
            status: "0",
            remark: "测站20",
          },
        ];
        mapManager.addVecAreaLyr();
        mapManager.addStations(list, {
          id: "stlyr",
          click: function (evt) {
            // 获取所有的属性
            var prop = evt.layer.feature.properties;
            // console.log(prop);
            //var html = ''
            // for (var i in prop) {
            //   html += i + '：' + prop[i] + '<br/>'
            // }
            // console.log('1111');
            openWindow(prop.sttp=='zm'?1:2);
            //return html
          },
        });
        //  mapManager.flyTo({x:106.17,y:37.94,z:17});
        // mapManager._onZoomEnd(list,{id: 'stlyr'},evt);
      });
    },
    // 获取监控列表
    getList() {
      this.monitorList({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res != false) {
            this.tableData = res.body.records;
            this.total = res.body.recordCount;
          }
        })
        .catch();
    },
    queryGateData(){
      axios
          .get('http://218.21.26.202:8081/9a8ee10c0bbc11e8ba890ed5f89f718b/getSiteRegisterList/1538/0')
          .then(response => (
          this.gateData = response.data,console.log(this.gateData))
          ).catch(function (error) { // 请求失败处理
            console.log(error);
          });
    },
    stationFly(index,row){
        this.mapWindow.getMapManager(function (mapManager) {
            console.log(row.deviceLocations);
            let pos ={
              x:row.deviceLocations[0][0],
              y:row.deviceLocations[0][1],
              z:13
            }
            // console.log(pos)
            mapManager.flyTo(pos);
        })
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.$parent.show1 = true
    }
    // this.createMap();
    this.drawCharts();
    this.getList();
    // this.queryGateData();
  },
};
</script>
<style lang="less" scoped>
.home {
  height: calc(100vh - 80px);
  width: 100vw;
  left: 0;
  top: 80px;
  position: fixed;
  overflow: hidden;
  .amap {
    width: calc(100% - 580px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    &.big-amap {
      width: 100%;
    }
    .small-tip {
      position: absolute;
      z-index: 9;
      left: calc(50% - 10px);
      top: 20px;
      font-size: 20px;
      display: inline-block;
      width: 20px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      cursor: pointer;
    }
    .tip {
      position: absolute;
      z-index: 9;
      left: calc(50% - 300px);
      top: 20px;
      display: inline-block;
      width: 600px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #ddd;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 2px #ddd;
      padding: 0 10px;
      cursor: auto;
      .tip-icon {
        font-size: 20px;
        color: rgb(245, 154, 45);
        margin-right: 20px;
        margin-left: 10px;
      }
      .tip-close {
        float: right;
        font-size: 16px;
        margin-top: 12px;
        cursor: pointer;
        &:hover {
          color: rgb(0, 185, 231);
        }
      }
    }
  }
  .drawer {
    position: absolute;
    width: 580px;
    height: 100%;
    display: inline-block;
    right: 0;
    top: 0;
    background-color: rgba(0, 185, 231, 0.8);
    z-index: 9;
    transform: translateX(0);
    transition: transform 0.3s ease-in;
    border-radius: 5px;
    cursor: auto;
    &.is-close {
      transform: translateX(100%);
    }
    .drawer-main {
      height: 100%;
      position: relative;
      .drawer-btn {
        display: inline-block;
        width: 40px;
        height: 100px;
        line-height: 100px;
        padding-left: 7px;
        color: #fff;
        position: absolute;
        left: -25px;
        top: calc(50% - 50px);
        border-radius: 18px;
        z-index: -1;
        background-color: rgba(0, 185, 231, 0.8);
        cursor: pointer;
      }
      .drawer-body {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 10px;
        background-color: #fff;
        border-radius: 5px;
        padding: 0 20px;
        .table-wrapper {
          height: calc(100% - 420px);
        }
        .title {
          font-size: 18px;
          height: 50px;
          line-height: 50px;
          font-weight: bold;
          text-align: center;
          color: rgb(0, 185, 231);
        }
        .row-item {
          margin-bottom: 10px;
          height: 40px;
          line-height: 40px;
          div {
            display: inline-block;
          }
          .label {
            color: #333;
            font-weight: 600;
            width: 80px;
            text-align: right;
          }
          .content {
            width: calc(100% - 80px);
            position: relative;
            .btn {
              position: absolute;
              right: 0;
            }
          }
        }
        .bar-charts {
          width: 100%;
          height: 120px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>