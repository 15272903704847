<template>
    <div class="page">
        <BreadCrumb :arr="arr" />
        <Title icon="fa fa-image" text="渠系概化图" />
        <img src="@/assets/p3.jpg" width="80%" alt="">
    </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
export default {
    components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "实时监测",
          path: '/monitor',
        },
        {
          text: "渠系概化图",
          path: null,
        },
      ],
    };
  },
  methods: {},
}
</script>