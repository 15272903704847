<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-hotdog" text="编辑数据字典" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 字典名称：</div>
            <div class="content">
              <el-input v-model="Form.name" clearable disabled maxlength="32">
              </el-input>
            </div>
          </div>
        </div>
        <div class="row" style="margin-bottom: 90px">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.remark"
                clearable
                maxlength="300"
                type="textarea"
              >
              </el-input>
            </div>
          </div>
        </div>
        <el-divider content-position="left"></el-divider>
        <div>
          <Button icon="fa fa-plus" text="增加字典值" @click="add()" />
          <Button
            icon="fa fa-trash-alt"
            text="删除空行"
            @click="del()"
            style="margin-left: 10px"
          />
          <el-table
            :data="Form.options"
            style="width: 100%; margin-top: 20px"
            height="300px"
            stripe
            border
            @selection-change="(val) => (selectArr = val)"
            ref="multipleTable"
          >
            <el-table-column
              label="序号"
              width="60"
              type="index"
            ></el-table-column>
            <el-table-column label="名称" width="200">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name" maxlength="20" clearable> </el-input>
              </template>
            </el-table-column>
            <el-table-column label="参数值" width="200">
              <template slot-scope="scope">
                <el-input
                  v-model="scope.row.code"
                  maxlength="20"
                  clearable
                  :disabled="scope.row.id != null ? true : false"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="备注">
              <template slot-scope="scope">
                <el-input v-model="scope.row.remark" maxlength="20"> </el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/system/dictionary')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "系统管理",
          path: null,
        },
        {
          text: "数据字典",
          path: "/system/dictionary",
        },
        {
          text: "编辑数据字典",
          path: null,
        },
      ],
      Form: {
        type: null,
        name: null,
        remark: null,
        options: [],
      },
    };
  },
  methods: {
    ...mapActions(["getDictionaryForType", "editDictionary"]),
    queryData() {
      this.getDictionaryForType({
        _this: this,
        type: this.Form.type,
      }).then((res) => {
        if (res != false) {
          this.Form = {
            ...this.Form,
            ...res.body,
          };
        }
      });
    },
    // 提交表单
    saveForm() {
      if (this.Form.name === null || this.Form.name === "") {
        this.$message.warning("名称不能为空！");
        return;
      }

      var msg = true;
      this.Form.options.forEach((item, index) => {
        if (msg === true) {
          if (item.name === null || item.name === "") {
            this.$message.warning(`字典值列表第${index + 1}行名称不能为空！`);
            msg = false;
          }
        }
        if (msg === true) {
          if (item.code === null || item.code === "") {
            this.$message.warning(`字典值列表第${index + 1}行参数值不能为空！`);
            msg = false;
          }
        }
      });
      if (msg === false) return;

      this.editDictionary({
        _this: this,
        type: this.Form.type,
        data: this.Form,
      }).then((res) => {
        if (res.statusCode === 2000) {
          this.$message.success("编辑成功");
          this.$router.push("/system/dictionary");
        } else {
          this.$message.error(res.notification);
        }
      });
    },
    add() {
      this.Form.options.push({
        code: null,
        name: null,
        remark: null,
      });
    },
    del() {
      this.Form.options = this.Form.options.filter((item) => {
        if (
          (item.code === null || item.code === "") &&
          (item.name === null || item.name === "") &&
          (item.remark === null || item.remark === "")
        ) {
          return false;
        } else return true;
      });
    },
  },
  mounted() {
    this.Form.type = this.$route.params.type;
    this.queryData();
  },
};
</script>