<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-pallet" text="闸站详情" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">闸站名称：</div>
            <div class="content">
              {{Form.station.name}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">所属灌域：</div>
            <div class="content">
              {{Form.station.irrigationDistrict.name}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">管理机构：</div>
            <div class="content">
              {{Form.station.organization.name}}
            </div>
          </div>
          <div class="search-item">
            <div class="label">闸站类型：</div>
            <div class="content">
              {{Form.type.name}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">闸板数量：</div>
            <div class="content">
              <span v-if="Form.boardQuantity === 0 || Form.boardQuantity > 0">{{Form.boardQuantity}}个</span>
            </div>
          </div>
          <div class="search-item">
            <div class="label">站址：</div>
            <div class="content">
              {{Form.station.address}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">净宽：</div>
            <div class="content">
              <span v-if="Form.width === 0 || Form.width > 0">{{Form.width}}m</span>
            </div>
          </div>
          <div class="search-item">
            <div class="label">净高：</div>
            <div class="content">
              <span v-if="Form.height === 0 || Form.height > 0">{{Form.height}}m</span>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              {{Form.station.remark}}
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/basic/gate/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  data() {
    return {
      arr: [
        {
          text: "基础信息",
          path: null,
        },
        {
          text: "闸站管理",
          path: "/basic/gate/page",
        },
        {
          text: "闸站详情",
          path: null,
        },
      ],
      Form: {
        id: null,
        station: {
          irrigationDistrict: {
            id: null,
            name: null
          },
          organization: {
            id: null,
          },
          name: null,
          remark: null
        },
        type: {
          id: null
        },
        boardQuantity: null,
        height: null,
        width: null,
      },
    };
  },
  methods: {
    ...mapActions(["GateDetail"]),
    queryData() {
      this.GateDetail({
        _this: this,
        id: this.Form.id,
      })
        .then((res) => {
          if (res !== false) {
            this.Form = {
              ...this.Form,
              ...res.body,
            };
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      let arr = this.$route.path.split("/");
      this.Form.id = arr[arr.length - 1];

      this.queryData();
    });
  },
};
</script>