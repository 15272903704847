<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-user-shield" text="编辑角色" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
              <div class="label"><span class="star">*</span> 角色名：</div>
            <div class="content">
              <el-input v-model="Form.text" clearable maxlength="12"> </el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <el-tree
            v-if="defaultNode.length"
            :data="tree"
            :props="defaultProps"
            :default-checked-keys="defaultNode"
            node-key="id"
            accordion
            show-checkbox
            @check="(a, b) => handleCheckChange(a, b)"
            style="margin-left: 130px"
          ></el-tree>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.remark"
                clearable
                maxlength="300"
                type="textarea"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/system/role/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button
  },
  data() {
    return {
      arr: [
        {
          text: "系统管理",
          path: null,
        },
        {
          text: "角色管理",
          path: "/system/role/page",
        },
        {
          text: "编辑角色",
          path: null,
        },
      ],
      Form: {
          id: null,
        text: null,
        remark: null,
        facades: [],
      },
      defaultProps: {
        children: "children",
        label: "text",
      },
      tree: [],
      defaultNode: []
    };
  },
  methods: {
    ...mapActions(["editRole", "detailRole", "getFullTree"]),
    // 提交表单
    saveForm() {
      if (this.Form.text === null || this.Form.text === "") {
        this.$message.warning("角色名不能为空！");
        return;
      }
      this.editRole({
        _this: this,
        id: this.Form.id,
        data: this.Form,
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("编辑成功！");
            this.$router.push("/system/role/page");
          }
        })
        .catch();
    },
    queryData() {
        this.detailRole({
        _this: this,
        id: this.Form.id,
      })
        .then((res) => {
          if (res !== false) {
            this.Form = {
                ...this.Form,
                ...res.body
            }
            this.getNode(this.Form.facades)
          }
        })
        .catch();
    },
    // 循环遍历获取节点id
    getNode(tree) {
        tree.forEach(item => {
            this.defaultNode.push(item.id)
            if (item.children != null && item.children.length > 0) {
                this.getNode(item.children)
            }
        })
    },
    handleCheckChange(a, b) {
      this.Form.facades = []
      b.checkedKeys.forEach(item => {
        this.Form.facades.push({
          id: item + ""
        })
      })
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.Form.id = this.$route.params.id;
      this.getFullTree(this)
        .then((res) => {
          this.queryData();
          if (res !== false) {
            this.tree = res.body.map((item) => {
              return {
                ...item,
                disabled: item.id == 1000 ? true : false,
              };
            });
          }
        })
        .catch();
    });
  },
};
</script>