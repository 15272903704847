<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-user-shield" text="编辑用户" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 姓名：</div>
            <div class="content">
              <el-input v-model="Form.name" maxlength="20" clearable> </el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="label">英文名：</div>
            <div class="content">
              <el-input v-model="Form.engName" maxlength="20" clearable> </el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 用户名：</div>
            <div class="content">
              <el-input v-model="Form.account" maxlength="20" disabled clearable> </el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 角色：</div>
            <div class="content">
              <el-select v-model="Form.roles" multiple collapse-tags placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 手机号：</div>
            <div class="content">
              <el-input v-model="Form.mobile" maxlength="20" clearable> </el-input>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 性别：</div>
            <div class="content">
              <el-select v-model="Form.gender" placeholder="请选择">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 所属机构：</div>
            <div class="content">
              <Organization
                @change="(val) => (Form.organization.id = val)"
                @select="handleSelect"
                :value="Form.organization.name"
                :key="Form.organization.name"
              />
            </div>
          </div>
          <div class="search-item">
            <div class="label">出生日期：</div>
            <div class="content">
              <el-date-picker
                v-model="Form.birthday"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.remark"
                clearable
                maxlength="300"
                type="textarea"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/system/user/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Organization from "@/components/Organization";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Organization,
    Title,
    Button,
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  data() {
    return {
      arr: [
        {
          text: "系统管理",
          path: null,
        },
        {
          text: "用户管理",
          path: "/system/user/page",
        },
        {
          text: "编辑用户",
          path: null,
        },
      ],
      Form: {
        id: null,
        mobile: null,
        account: null,
        name: null,
        engName: null,
        gender: null,
        phone: null,
        birthday: null,
        nickname: null,
        roles: [],
        organization: {
          id: null,
        },
        remark: null,
      },
      options: [],
      options2: [],
    };
  },
  methods: {
    ...mapActions(["editUser", "getRolePage", "getDictionaryForType", "getUser"]),
    handleSelect(val) {
      this.Form.organization.id = val;
    },
    // 提交表单
    saveForm() {
      if (this.Form.name === "" || this.Form.name === null) {
        this.$message.warning("姓名不能为空！");
        return;
      }
      if (this.Form.account === "" || this.Form.account === null) {
        this.$message.warning("用户名不能为空！");
        return;
      }
      if (this.Form.account.length < 4 || this.Form.account.length > 20) {
        this.$message.warning("用户名长度应为4-20位！");
        return;
      }
      if (this.Form.roles.length === 0) {
        this.$message.warning("最少需选择一个角色！");
        return;
      }
      if (this.Form.mobile === "" || this.Form.mobile === null) {
        this.$message.warning("手机号不能为空！");
        return;
      }
      var reg = /^((13[0-9])|(14[0-9])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/;
      var bool = reg.test(this.Form.mobile);
      if (bool === false) {
        this.$message.warning("请输入正确的手机号！");
        return;
      }
      if (this.Form.gender === "" || this.Form.gender === null) {
        this.$message.warning("性别不能为空！");
        return;
      }
      this.editUser({
        _this: this,
        id: this.Form.id,
        data: {
          ...this.Form,
          birthday: this.$options.filters["dateformat"](
            this.Form.birthday,
            "YYYY-MM-DD"
          ),
          roles: this.Form.roles.map((item) => {
            return {
              id: item,
            };
          }),
        },
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("编辑成功！");
            this.$router.push("/system/user/page");
          }
        })
        .catch();
    },
    queryData() {
        this.getUser({
        _this: this,
        id: this.Form.id,
      })
        .then((res) => {
          if (res !== false) {
            this.Form = {
                ...this.Form,
                ...res.body,
                gender: res.body.gender + "",
                roles: res.body.roles.map(item => item.id)
            }
          }
        })
        .catch();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.Form.id = this.$route.params.id;
      this.queryData();
      this.getRolePage({
        _this: this,
        data: {
          pageIndex: 1,
          pageSize: 999,
          enabled: true,
        },
      })
        .then((res) => {
          if (res.statusCode == 2000) {
            res.body.records.forEach((item) => {
              this.options.push({
                value: item.id,
                label: item.text,
              });
            });
          } else {
            this.$message.error(res.notification);
          }
        })
        .catch();

      // 获取性别下拉选项
      this.options2 = this.allOptions.filter((item) => item.type === "Gander");
      if (this.options2.length > 0) {
        this.options2 = this.options2[0].arr;
      } else {
        // 没有就去查
        this.getDictionaryForType({
          _this: this,
          type: "Gander",
        })
          .then((res) => {
            if (res !== false) {
              res.body.options.forEach((item) => {
                this.options2.push({
                  value: item.id + "",
                  label: item.name,
                });
              });
            }
          })
          .catch();
      }
    });
  },
};
</script>