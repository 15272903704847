<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-fill-drip" :text="Form.id ? Form.station.name : ''" />
    <div class="monitor-top">
      <div class="top-item apply-body">
        <div class="apply-header">
          <i class="fa fa-cloud-rain" style="margin: 0 10px"></i>
          水雨情
        </div>
        <div class="search-div" style="border: none">
          <div class="search-item">
            <div class="label">采集时间：</div>
            <div class="content" style="width: 360px">
              <el-date-picker
                v-model="Rain.time"
                type="datetimerange"
                range-separator="至"
                style="transform: translateY(5px)"
                format="yyyy-MM-dd HH:00"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div>
          <Button
            icon="fa fa-search"
            class="btn"
            text="查询"
            @click="drawChartRain()"
          />
          <Button
            icon="fa fa-file-export"
            class="btn"
            text="导出"
            style="margin-left: 10px"
            @click="exportRain()"
          />
          <Button
            icon="fa fa-list"
            class="btn fr clearfix"
            @click="Rain.active = 2"
            :class="Rain.active === 2 ? '' : 'not-active'"
            text="列表"
          />
          <Button
            icon="fa fa-chart-bar"
            class="btn fr clearfix"
            @click="Rain.active = 1"
            :class="Rain.active === 1 ? '' : 'not-active'"
            text="图表"
          />
        </div>
        <div class="chart1" ref="chart1" v-show="Rain.active === 1"></div>

        <div class="table-div" v-show="Rain.active === 2">
          <el-table
            :data="rainData"
            border
            style="width: 100%; margin-top: 20px"
            height="360px"
          >
            <el-table-column label="时间">
              <template slot-scope="scope">
                {{ scope.row.collectTime | dateformat }}
              </template>
            </el-table-column>
            <el-table-column prop="level" label="水位(m)"></el-table-column>
            <el-table-column prop="rainfall" label="流量(m³/s)"></el-table-column>
          </el-table>
          <div style="text-align: center; margin: 5px 0">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="Rain.pageIndex"
              :page-size="Rain.pageSize"
              :total="Rain.total"
              @size-change="(val) => (Rain.pageSize = val)"
              @current-change="(val) => (Rain.pageIndex = val)"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="top-item apply-body" style="margin-left: 20px">
        <div class="apply-header">
          <i class="fa fa-water" style="margin: 0 10px"></i>
          用水量
        </div>
        <div class="search-div" style="border: none">
          <div class="search-item">
            <div class="label">采集时间：</div>
            <div class="content" style="width: 360px">
              <el-date-picker
                v-model="Water.time"
                type="datetimerange"
                range-separator="至"
                style="transform: translateY(5px)"
                format="yyyy-MM-dd HH:00"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div>
          <Button icon="fa fa-search" class="btn" text="查询" @click="drawChartWater()" />
          <Button
            icon="fa fa-file-export"
            class="btn"
            text="导出"
            style="margin-left: 10px"
            @click="exportWater()"
          />
          <Button
            icon="fa fa-list"
            class="btn fr clearfix"
            @click="Water.active = 2"
            :class="Water.active === 2 ? '' : 'not-active'"
            text="列表"
          />
          <Button
            icon="fa fa-chart-bar"
            class="btn fr clearfix"
            @click="Water.active = 1"
            :class="Water.active === 1 ? '' : 'not-active'"
            text="图表"
          />
        </div>
        <div class="chart2" ref="chart2" v-show="Water.active === 1"></div>
        <div class="table-div" v-show="Water.active === 2">
          <el-table
            :data="waterData"
            border
            style="width: 100%; margin-top: 20px"
            height="360px"
          >
            <el-table-column label="时间">
              <template slot-scope="scope">
                {{ scope.row.time | dateformat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="quantity"
              label="用水量(m³)"
            ></el-table-column>
          </el-table>
          <div style="text-align: center; margin: 5px 0">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="Water.pageIndex"
              :page-size="Water.pageSize"
              :total="Water.total"
              @size-change="(val) => (Water.pageSize = val)"
              @current-change="(val) => (Water.pageIndex = val)"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="apply-body station-info">
      <div class="apply-header">
        <i class="fa fa-broadcast-tower" style="margin: 0 10px"></i>
        测站信息
      </div>
      <div v-if="type == 1">
        <div class="info-item">
          <div class="info-l">闸站名称：</div>
          <div class="info-r">
            <span v-if="Form.id">{{ Form.station.name }}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">所属灌域：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.station.irrigationDistrict.name}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">管理机构：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.station.organization.name}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">闸站类型：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.type.name}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">闸板数量：</div>
          <div class="info-r">
            <span v-if="Form.id && Form.boardQuantity > 0">{{Form.boardQuantity}}个</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">站址：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.station.address}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">净宽：</div>
          <div class="info-r">
            <span v-if="Form.id && Form.width > 0">{{Form.width}}m</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">净高：</div>
          <div class="info-r">
            <span v-if="Form.id && Form.height > 0">{{Form.height}}m</span>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="info-item">
          <div class="info-l">测站名称：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.station.name}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">所属灌域：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.station.irrigationDistrict.name}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">管理机构：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.station.organization.name}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">行政区划：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.region.name}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">站址：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.station.address}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">测站类型：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.type.name}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">始报年月：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.firstDate | dateformat('YYYY-MM')}}</span>
          </div>
        </div>
        <div class="info-item">
          <div class="info-l">建站日期：</div>
          <div class="info-r">
            <span v-if="Form.id">{{Form.buildDate | dateformat('YYYY-MM-DD')}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="search-div" style="border: none; transform: translateY(20px)">
      <div class="search-item">
        <div class="label">采集时间：</div>
        <div class="content">
          <el-date-picker
            v-model="Rain.time"
            type="datetimerange"
            range-separator="至"
            style="transform: translateY(5px)"
            format="yyyy-MM-dd HH:00"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="search-item">
        <Button
          icon="fa fa-search"
          class="btn"
          style="margin-left: 20px"
          text="查询"
        />
      </div>
    </div>
    <!-- 轮播图 -->
    <div class="carousel">
      <div
        class="carousel-wrapper"
        :style="'width: calc(' + 25 * 10 + 'vw); margin-left: ' + marginLeft"
      >
        <div class="carousel-item" v-for="item in 10" :key="item">
          <img src="../../assets/t3.png" class="item-img" alt="" />
        </div>
      </div>
      <div class="carousel-l carousel-btn">
        <i class="fa fa-angle-double-left" @click="prevImg()"></i>
      </div>
      <div class="carousel-r carousel-btn">
        <i class="fa fa-angle-double-right" @click="nextImg()"></i>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import * as echarts from "echarts";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
  },
  watch: {
    "Rain.pageIndex"() {
      this.queryRainList();
    },
    "Water.pageIndex"() {
      this.queryWaterList();
    }
  },
  computed: {
    ...mapState(["now"]),
    marginLeft() {
      let left = `calc(-${25 * this.index}vw + ${39 * this.index}px)`;
      return left;
    },
  },
  data() {
    return {
      arr: [
        {
          text: "实时监测",
          path: "/monitor",
        },
        {
          text: "测站详情",
          path: null,
        },
      ],
      id: null,
      type: null,
      Rain: {
        time: [],
        active: 1,
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      Water: {
        time: [],
        active: 1,
        pageIndex: 1,
        pageSize: 10,
        total: 0,
      },
      index: 0,
      rainData: [],
      waterData: [],
      Form: {},
    };
  },
  methods: {
    ...mapActions([
      "rainChart",
      "rainList",
      "waterChart",
      "waterList",
      "GateDetail",
      "StationDetail",
      "exportRainTable",
      "exportWaterTable"
    ]),
    // 切换上下图
    prevImg() {
      this.index--;
      this.index = this.index < 0 ? 0 : this.index;
    },
    nextImg() {
      this.index++;
      this.index = this.index > 9 ? 9 : this.index;
    },
    // 水雨情分页
    queryRainList() {
      let data = {
        startRecordCollectTime: null,
        endRecordCollectTime: null,
      };
      if (this.Rain.time.length > 0) {
        data.startRecordCollectTime = this.$utils.fixDateTime(
          this.Rain.time[0]
        );
        data.endRecordCollectTime = this.$utils.fixDateTime(this.Rain.time[1]);
      }
      this.rainList({
        _this: this,
        id: this.id,
        data
      }).then((res) => {
        if (res != false) {
          this.rainData = res.body.records;
          this.Rain.total = res.body.recordCount;
        }
      });
    },
    // 水雨情图表
    drawChartRain() {
      this.queryRainList();
      let series,
        xaxis,
        data = {
          startTime: null,
          endTime: null,
        };
      if (this.Rain.time.length > 0) {
        data.startTime = this.$utils.fixDateTime(this.Rain.time[0]);
        data.endTime = this.$utils.fixDateTime(this.Rain.time[1]);
      }
      this.rainChart({
        _this: this,
        id: this.id,
        data
      })
        .then((res) => {
          if (res != false) {
            xaxis = res.body.histogram.xaxis;
            series = res.body.histogram.series;

            let xAxis = [
              {
                type: "category",
                boundaryGap: false,
                axisLine: { onZero: false },
                data: xaxis.map(function (str) {
                  return str.replace(" ", "\n");
                }),
              },
            ];
            series = series.map((item) => {
              return {
                type: "line",
                areaStyle: {},
                lineStyle: {
                  width: 1,
                },
                emphasis: {
                  focus: "series",
                },
                ...item,
              };
            });
            var echartsDom = echarts.init(this.$refs.chart1);
            echartsDom.setOption({
              title: {
                text: "水位-流量直方图",
                left: "center",
                align: "right",
              },
              grid: {
                bottom: 80,
                top: 70,
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  animation: false,
                  label: {
                    backgroundColor: "#505765",
                  },
                },
              },
              legend: {
                data: ["流量", "水位"],
                left: 10,
              },
              dataZoom: [
                {
                  type: "slider",
                  realtime: true,
                  left: "150",
                  right: "150",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: xAxis,
              yAxis: [
                {
                  name: "流量(m³/s)",
                  type: "value",
                },
                {
                  name: "水位(m)",
                  nameLocation: "start",
                  type: "value",
                  inverse: true,
                },
              ],
              series: series,
            });
            var zoomSize = 6;
            echartsDom.on("click", function (params) {
              echartsDom.dispatchAction({
                type: "dataZoom",
                startValue:
                  dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
                endValue:
                  dataAxis[
                    Math.min(params.dataIndex + zoomSize / 2, data.length - 1)
                  ],
              });
            });
          }
        })
        .catch();
    },
    // 用水量分页
    queryWaterList() {
      let data = {
          ...this.Water,
          startTime: null,
          endTime: null,
          stationId: this.id
        };
      if (this.Water.time.length > 0) {
        data.startRecordCollectTime = this.$utils.fixDateTime(this.Water.time[0]);
        data.endRecordCollectTime = this.$utils.fixDateTime(this.Water.time[1]);
      }
      this.waterList({
        _this: this,
        id: this.id,
        data,
      }).then((res) => {
        if (res != false) {
          this.waterData = res.body.records;
          this.Water.total = res.body.recordCount;
        }
      });
    },
    // 用水量图
    drawChartWater() {
      this.queryWaterList();

      let series,
        xaxis,
        data = {
          startTime: null,
          endTime: null,
        };
      if (this.Water.time.length > 0) {
        data.startTime = this.$utils.fixDateTime(this.Water.time[0]);
        data.endTime = this.$utils.fixDateTime(this.Water.time[1]);
      }
      this.waterChart({
        _this: this,
        id: this.id,
        data
      })
        .then((res) => {
          if (res != false) {
            xaxis = res.body.xaxis;
            series = res.body.series;
            series = series.map((item) => {
              return {
                type: "line",
                symbol: "none",
                sampling: "lttb",
                itemStyle: {
                  color: "rgb(255, 70, 131)",
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgb(255, 158, 68)",
                    },
                    {
                      offset: 1,
                      color: "rgb(255, 70, 131)",
                    },
                  ]),
                },
                ...item,
              };
            });
            var echartsDom = echarts.init(this.$refs.chart2);
            echartsDom.setOption({
              tooltip: {
                trigger: "axis",
                position: function (pt) {
                  return [pt[0], "10%"];
                },
              },
              title: {
                text: "用水量直方图",
                left: "center",
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                data: xaxis,
              },
              yAxis: {
                name: "用水量(m)",
                type: "value",
                boundaryGap: [0, "100%"],
              },
              dataZoom: [
                {
                  type: "slider",
                  realtime: true,
                  left: "150",
                  right: "150",
                  start: 0,
                  end: 100,
                },
              ],
              series: series,
            });
          }
        })
        .catch();
    },
    // 测站信息
    queryData() {
      if (this.type == 1) {
        this.GateDetail({
          _this: this,
          id: this.id,
        })
          .then((res) => {
            if (res != false) {
              this.Form = res.body;
            }
          })
          .catch();
      } else {
        this.StationDetail({
          _this: this,
          id: this.id,
        })
          .then((res) => {
            if (res != false) {
              this.Form = res.body;
            }
          })
          .catch();
      }
    },
    exportRain() {
      if (this.Rain.time.length === 0) {
        this.$message.warning("请选择采集时间后再导出！")
        return
      }
      let data = {
        startRecordCollectTime: null,
        endRecordCollectTime: null,
        stationId: this.id,
      };
      if (this.Rain.time.length > 0) {
        data.startRecordCollectTime = this.$utils.fixDateTime(
          this.Rain.time[0]
        );
        data.endRecordCollectTime = this.$utils.fixDateTime(this.Rain.time[1]);
      }
      this.exportRainTable({
        _this: this,
        id: this.id,
        data,
        filename: "水雨情表格.xlsx"
      })
    },
    exportWater() {
      if (this.Rain.time.length === 0) {
        this.$message.warning("请选择采集时间后再导出！")
        return
      }
      let data = {
          ...this.Water,
          startTime: null,
          endTime: null,
          stationId: this.id
        };
      if (this.Rain.time.length > 0) {
        data.startRecordCollectTime = this.$utils.fixDateTime(this.Rain.time[0]);
        data.endRecordCollectTime = this.$utils.fixDateTime(this.Rain.time[1]);
      }
      this.exportWaterTable({
        _this: this,
        id: this.id,
        data,
        filename: "用水量表格.xlsx"
      })
    }
  },
  mounted() {
    let arr = this.$route.path.split("/");
    let str = arr[arr.length - 1];
    let arr2 = str.split("-");
    this.type = arr2[0];
    this.id = arr2[1];
    let time =
      Date.parse(new Date(this.now)) - 60 * 60 * 24 * 7 * 1000 - 60 * 60 * 3;
    let date = this.$options.filters["dateformat"](time, "YYYY-MM-DD");
    this.Rain.time.push(date, this.now);
    this.Water.time.push(date, this.now);
    this.queryData();
    this.drawChartRain();
    this.drawChartWater();
  },
};
</script>
<style lang="less" scoped>
.not-active {
  background-color: rgba(196, 196, 196, 1);
  &:hover {
    background-color: rgba(196, 196, 196, 1);
  }
}
.monitor-top {
  height: 400px;
  padding: 0 20px;
  .top-item {
    display: inline-block;
    width: calc(50% - 10px);
    vertical-align: top;
    .chart1,
    .chart2 {
      height: 400px;
      margin-top: 20px;
    }
  }
}
.station-info {
  margin-top: 200px;
  width: 100%;
  padding: 0 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  .info-item {
    width: 360px;
    display: inline-block;
    vertical-align: top;
    > div {
      display: inline-block;
    }
    .info-l {
      text-align: right;
      width: 150px;
      font-weight: bold;
      color: #000;
      margin-top: 15px;
      vertical-align: top;
    }
    .info-r {
      width: calc(100% - 150px);
      color: #000;
      margin-top: 15px;
      vertical-align: top;
    }
  }
}
.carousel {
  padding: 0 85px;
  height: 24vh;
  position: relative;
  margin: 30px 0;
  overflow: hidden;
  .carousel-wrapper {
    height: 100%;
    width: calc(100% - 60px);
    transform: translateX(-18px);
    transition: margin 0.4s linear;
    -ms-transition: margin 0.4s linear;
    -moz-transition: margin 0.4s linear;
    -webkit-transition: margin 0.4s linear;
    -o-transition: margin 0.4s linear;
    .carousel-item {
      display: inline-block;
      height: 100%;
      width: calc(25vw - 58px);
      margin: 0 10px;
      border: 1px solid #ddd;
      box-shadow: 0 0 3px #ddd;
      .item-img {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 10px;
      }
    }
  }
  .carousel-btn {
    position: absolute;
    height: 100%;
    background-color: #fff;
    top: 0;
    i {
      font-size: 48px;
      color: #409efb;
      cursor: pointer;
      margin-top: calc(12vh - 20px);
    }
  }
  .carousel-l {
    left: 0;
    padding-left: 25px;
  }
  .carousel-r {
    right: 0;
    padding-right: 25px;
  }
}
</style>