<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-hotdog" text="编辑参数" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label">类别：</div>
            <div class="content">
              {{ Form.category }}
            </div>
          </div>
          <div class="search-item">
            <div class="label">名称：</div>
            <div class="content">
              {{ Form.name }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 值：</div>
            <div class="content">
              <el-input v-model="Form.content" clearable maxlength="8"> </el-input>
            </div>
          </div>
          <div class="search-item"></div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/system/params/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import IrrigationSelect from "@/components/IrrigationSelect";
import { mapActions } from "vuex";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    IrrigationSelect,
  },
  data() {
    return {
      arr: [
        {
          text: "系统管理",
          path: null,
        },
        {
          text: "参数设置",
          path: "/system/params/page",
        },
        {
          text: "参数编辑",
          path: null,
        },
      ],
      Form: {
        id: null,
        category: null,
        name: null,
        content: null,
      },
    };
  },
  methods: {
    ...mapActions(["editParams"]),
    // 提交表单
    saveForm() {
      if (this.Form.content === null || this.Form.content === "") {
        this.$message.warning("值不能为空！");
        return;
      }
      this.editParams({
        _this: this,
        id: this.Form.id,
        data: this.Form,
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("编辑成功！");
            this.$router.push("/system/params/page");
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.Form = {
          ...this.Form,
          ...this.$route.query
      }
    });
  },
};
</script>