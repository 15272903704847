<template>
  <div class="page">
    <BreadCrumb :arr="arr" />
    <Title icon="fa fa-retweet" text="编辑水权交易" />
    <div class="apply-body">
      <div class="apply-main">
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 买方机构：</div>
            <div class="content">
              <Organization
                :value="Form.buyer.name"
                :key="Form.buyer.name"
                @change="val => Form.buyer.id = val"
                @select="handleSelect1"
                :typeId="6"
                :haveId="Form.seller.id"
              />
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 卖方机构：</div>
            <div class="content">
              <Organization
                :value="Form.seller.name"
                :key="Form.seller.name"
                @change="val => Form.seller.id = val"
                @select="handleSelect2"
                :typeId="6"
                :haveId="Form.buyer.id"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 交易时间：</div>
            <div class="content">
              <el-date-picker v-model="Form.transactionTime" type="datetime">
              </el-date-picker>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 交易水量：</div>
            <div class="content">
              <el-input
                v-model="Form.waterQuantity"
                clearable
                maxlength="32"
                placeholder="单位：m³"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
              >
              </el-input>
              <div class="content-footer">m³</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 单价：</div>
            <div class="content">
              <el-input
                v-model="Form.price"
                type="number"
                oninput="if(value.length>8)value=value.slice(0,8)"
                @keydown.native="$utils.btKeyUp"
                clearable
                maxlength="32"
                placeholder="单位：元"
              >
              </el-input>
              <div class="content-footer">元</div>
            </div>
          </div>
          <div class="search-item">
            <div class="label"><span class="star">*</span> 经办人：</div>
            <div class="content">
              <el-input v-model="Form.operator" clearable maxlength="32">
              </el-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="search-item">
            <div class="label">备注：</div>
            <div class="content">
              <el-input
                :rows="5"
                v-model="Form.remark"
                clearable
                maxlength="300"
                type="textarea"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 80px;">
          <div class="search-item">
            <div class="label"><span class="star">*</span> 附件：</div>
            <div class="content">
              <el-upload
                class="upload-demo"
                ref="upload"
                accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.JPG,.JPEG,.PBG,.DOC,.DOCX,.XLS,.XLSX,.PDF,.ZIP,.RAR,.7Z,.zip,.rar,.7z"
                action=""
                :on-remove="handleRemove"
                multiple
                :limit="1"
                :http-request="uploadFile"
                :file-list="Form.attachments"
              >
                <el-button
                  size="small"
                  type="primary"
                  title="点击按钮，上传文件"
                  :disabled="Form.attachments.length > 0"
                  >点击上传</el-button
                >
              </el-upload>
            </div>
          </div>
        </div>
        <div class="btn-list">
          <Button
            icon="fa fa-check-double"
            class="btn"
            text="保存"
            @click="saveForm()"
          />
          <Button
            icon="fa fa-reply-all"
            class="btn"
            text="返回"
            @click="$router.push('/water/transaction/page')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import Title from "@/components/Title";
import Button from "@/components/Button";
import { mapActions, mapState } from "vuex";
import Organization from "@/components/Organization";
export default {
  components: {
    BreadCrumb,
    Title,
    Button,
    Organization,
  },
  computed: {
    ...mapState(["allOptions"]),
  },
  data() {
    return {
      arr: [
        {
          text: "用水管理",
          path: null,
        },
        {
          text: "水权交易",
          path: "/water/transaction/page",
        },
        {
          text: "编辑水权交易",
          path: null,
        },
      ],
      Form: {
        id: null,
        seller: {
          id: null,
          name: null,
        },
        buyer: {
          id: null,
          name: null,
        },
        transactionTime: null,
        waterQuantity: null,
        price: null,
        operator: null,
        remark: null,
        attachments: []
      },
    };
  },
  methods: {
    ...mapActions(["EditTransaction", "TransactionDetail", "upload"]),
    handleSelect1(val) {
      this.Form.buyer.id = val;
    },
    handleSelect2(val) {
      this.Form.seller.id = val;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.Form.attachments = [];
    },
    // 上传附件
    uploadFile(file) {
      var params = new FormData(); // 创建form对象
      params.append("file", file.file); // 将文件存入file下面
      this.upload({
        _this: this,
        file: params,
      })
        .then((res) => {
          if (res != false) {
            this.Form.attachments.push({
              ...res.body,
              name: res.body.filename
            });
            this.$message.success("上传成功！");
          } else {
            this.$refs.upload.clearFiles();
            this.$message.error("上传失败！");
          }
        })
        .catch();
    },
    // 提交表单
    saveForm() {
      if (this.Form.buyer.id === null || this.Form.buyer.id === "") {
        this.$message.warning("买方机构不能为空！")
        return
      }
      if (this.Form.seller.id === null || this.Form.seller.id === "") {
        this.$message.warning("卖方机构不能为空！")
        return
      }
      if (this.Form.transactionTime === null || this.Form.transactionTime === "") {
        this.$message.warning("交易时间不能为空！")
        return
      }
      if (this.Form.waterQuantity === null || this.Form.waterQuantity === "") {
        this.$message.warning("交易水量不能为空！")
        return
      }
      if (this.Form.price === null || this.Form.price === "") {
        this.$message.warning("单价不能为空！")
        return
      }
      if (this.Form.operator === null || this.Form.operator === "") {
        this.$message.warning("经办人不能为空！")
        return
      }
      if (this.Form.attachments=== null || this.Form.attachments.length === 0) {
        this.$message.warning("附件不能为空！")
        return
      }
      this.EditTransaction({
        _this: this,
        data: {
          ...this.Form,
          transactionTime: this.$utils.fixDateTime(this.Form.transactionTime)
        },
        id: this.Form.id,
      })
        .then((res) => {
          if (res !== false) {
            this.$message.success("编辑成功！");
            this.$router.push("/water/transaction/page");
          }
        })
        .catch();
    },
    queryData(id) {
      this.Form.id = id;
      this.TransactionDetail({
        _this: this,
        id,
        data: this.Form,
      })
        .then((res) => {
          if (res !== false) {
            this.Form = {
              ...this.Form,
              ...res.body,
            };
            this.Form.attachments = this.Form.attachments.map(item => {
              return {
                ...item,
                name: item.filename
              }
            })
          }
        })
        .catch();
    },
  },
  mounted() {
    this.$nextTick(() => {
      let arr = this.$route.path.split("/");
      this.queryData(arr[arr.length - 1]);
    });
  },
};
</script>