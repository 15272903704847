<template>
  <el-cascader
    v-model="value"
    placeholder="请选择"
    :options="regionTree"
    :props="props"
    filterable
    :key="keyBool"
    ref="cascader"
    @change="handleChange"
  ></el-cascader>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["val"],
  data() {
    return {
      value: "",
      regionTree: [],
      props: {
        value: "code",
        label: "name",
        checkStrictly: true,
        emitPath: false,
      },
      Query: {
        code: "640000000000",
        lastGrade: null,
      },
      keyBool: false
    };
  },
  methods: {
    ...mapActions(["getReginTree"]),
    handleChange(value) { 
      this.$emit('change', value)
    },
    queryData() {
      this.getReginTree({
        _this: this,
        data: this.Query,
      })
        .then((res) => {
          if (res != false) {
            this.regionTree = res.body;
            if (this.val) {
              this.value = this.searchCode(this.regionTree, this.val);
              this.keyBool = true
            }
          }
        })
        .catch();
    },
    searchCode(arr, value) {
      let code = "";
      arr.forEach((item) => {
        if (item.id == value) {
          code = item.code;
        } else {
          if (
            code === "" &&
            item.children != null &&
            item.children.length > 0
          ) {
            code = this.searchCode(item.children, value);
          }
        }
      });
      return code;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.queryData();
    });
  },
};
</script>